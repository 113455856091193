.testimonial-carousel {
  max-width: 100vw;

  .slick-slide {
    padding: 0 10px;

    @include breakpoint(tablet) {
      padding: 0 $spacing-sm;
    }
  }

  .slick-active {
    opacity: 1;
  }

  .slick-dots {
    li {
      margin: 0;
    }

    bottom: -40px;
  }
}

.testimonial-carousel__title {
  font-size: 14px;
  text-align: left;
  line-height: 1.21;
  letter-spacing: 1.2px;
  margin-bottom: $spacing-lg;
}

.testimonial-card {
  color: $white;
  position: relative;
  height: 480px;

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
  }

  @include breakpoint(tablet) {
    height: 540px;
  }
}

.testimonial-card__content {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0 $spacing-md;
}

.testimonial-card__quote {
  margin: $spacing-md 0 $spacing-sm;
  line-height: 1;
  font-weight: bold;
  font-size: 30px;

  @include breakpoint(desktop) {
    font-size: 42px;
    line-height: 1.08;
  }

  // Quotes
  &::before {
    display: block;
    padding-left: $spacing-xs;
    content: '\201C';
    font-size: inherit;
    position: absolute;
    left: -20px;

    @include breakpoint(desktop-xlarge) {
      padding-left: 5px;
    }
  }

  &::after {
    display: inline-block;
    content: '\201D';
    font-size: inherit;
    position: absolute;
  }
}

.testimonial-card__quote--identifier {
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 2px 0 0;
}

.testimonial-card__quote--subtitle {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.testimonial-card__button {
  position: absolute;
  bottom: $spacing-md;
}

.slick-arrow {
  height: fit-content;
  width: fit-content;
  z-index: 1;

  &.slick-next {
    position: absolute;
    top: -41px !important;
    right: 0 !important;

    @include breakpoint(tablet) {
      top: -35px !important;
    }
  }

  &.slick-prev {
    display: none !important;
  }
}

.testimonial-card__overlay {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, rgba(40, 40, 40, 0.3) 20%, rgba(255, 255, 255, 0) 68%);
}
