.compact-star-rating {
  display: flex;
  align-items: center;
}

.compact-star-rating__star {
  width: 12px;
}

.compact-star-rating__count {
  margin-left: 4px;
  margin-top: $font-vertical-align-offset;
}
