@import '../core';

.technology-features {
  padding-bottom: 0;
}

.technology-features--light,
.technology-features--gray {
  color: $white;

  > * {
    color: inherit;
  }
}

.technology-features__feature {
  padding: $spacing-xl 0;
  border-bottom: 1px solid $grey2;

  &:first-of-type {
    padding-top: $spacing-md;
  }
}

.technology-features__image {
  @include breakpoint(desktop) {
    text-align: center;
  }
}
