.newsletter-section {
  background-color: $grey1;
  padding-bottom: $spacing-md;
  padding-top: $spacing-md;
}

.newsletter-section__min-age-text {
  padding-bottom: $spacing-md;
}

.newsletter-section__button {
  margin-bottom: $spacing-md;
}

.newsletter-section__message {
  margin-bottom: $spacing-md;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(#{$spacing-lg} + 2px + 12px * 1.2);
}

.newsletter-section__bottom-text {
  font-size: 14px;
}
