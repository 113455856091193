.build-your-plan__removed-from-cart__pod {
  display: flex;
  align-items: flex-start; // Fix Safari stretching image to its natural height instead of container height
  margin-bottom: 24px;
}

.build-your-plan__removed-from-cart__pod-image {
  width: 64px;
}

.build-your-plan__removed-from-cart__pod-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 4px;

  .rtl-direction & {
    margin-right: 4px;
    margin-left: 0;
  }
}

.build-your-plan__removed-from-cart__pod-name {
  font-weight: bold;
}
