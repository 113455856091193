.markdown-content > * {
  margin: 0;

  + * {
    margin-top: 1rem;
  }
}

.label--dimmed,
.label--green {
  @extend .subheading--small;

  & + * {
    margin-top: 0.25rem;
  }
}

.label--dimmed {
  color: $grey3;
  margin-top: 1.5rem;
}

.label--green {
  color: $autoship-green;
}
