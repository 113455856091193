@import '../core';

div.js-mini-header {
  background-color: $white;
  height: $spacing-xl;
  padding: 0;
  border-bottom: $grey2 solid 1px;

  @include breakpoint(desktop) {
    height: $spacing-xxl;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 15px $spacing-md;

    @include breakpoint(tablet) {
      padding: 15px $spacing-xl;
    }

    @include breakpoint(desktop) {
      padding: 23px $spacing-xxl;
    }

    @include breakpoint(desktop-xlarge) {
      padding: 23px $spacing-xxxxl;
    }
  }

  &__title {
    height: 18px;
    line-height: $spacing-sm;
    text-align: center;
    letter-spacing: 0.4px;
  }

  &__logo {
    height: 18px;
    margin-left: auto;

    img {
      height: $spacing-sm;
    }

    @include breakpoint(tablet) {
      min-width: $spacing-xl;
      margin-left: inherit;
      order: -1;
    }
  }

  &__language {
    @include breakpoint(tablet) {
      min-width: $spacing-xl;
    }

    .store-locale-selector {
      height: 18px;
      padding: 0;

      &__locale-name {
        &::after {
          background-image: url('/images/icons_v2/chevron-down.svg');
          background-size: contain;
          content: '';
          display: block;
          position: absolute;
          height: $spacing-sm;
          width: $spacing-sm;
          right: -$spacing-sm;
          top: 1px;
        }
      }

      &__dropdown {
        width: fit-content;
        background-color: inherit;
        border: 1px solid $grey2;
        top: 100%;
        bottom: auto;
        left: -$spacing-xl;
      }

      &__dropdown-item {
        padding: $spacing-sm;
        background-color: $white;
      }
    }
  }

  &__language-body {
    padding-left: 20px;
    padding-right: $spacing-sm;
  }
}
