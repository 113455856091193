$width-overlay-gap: 65px;

.mobile-navigation__container {
  box-shadow: none; // Reset for header__nav-wrapper
  border-top: 0; // Reset for header__nav-wrapper
  border-bottom: 1px solid $grey2;
  z-index: zindex(cart-mobile) - 1;

  @include breakpoint(tablet) {
    display: none;
  }
}

.mobile-navigation__sidebar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: zindex(sidenav);
}

.mobile-navigation__sidebar--open {
  display: block;
}

.mobile-navigation__overlay {
  position: absolute;
  inset: 0;
  background-color: rgba($black, 0.8);
  z-index: 1;
}

// Basic <ul> resets
.mobile-navigation__list {
  margin: 0;
  list-style: none;

  &,
  [dir="rtl"] & {
    padding-left: 0;
    padding-right: 0;
  }
}

.mobile-navigation__close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: none;
  padding: 20px;
  z-index: 2;

  [dir="rtl"] & {
    right: auto;
    left: 0;
  }
}

.mobile-navigation__open-button {
  appearance: none;
  border: 0;
  background: none;
  padding: $spacing-sm $spacing-sm $spacing-sm 0;
}

.mobile-navigation__open-button__icon {
  display: block;
}

.mobile-navigation__close-button__icon {
  display: block;
  filter: brightness(0) invert(1); // turns our dark icon white
}

.mobile-navigation__logo__icon {
  display: block;
  height: 61px;
  padding-top: 18px;
  padding-bottom: 17px;
  margin-left: auto;
  margin-right: auto;
}

.mobile-navigation__right-icons {
  display: flex;
  justify-content: flex-end;
}

.mobile-navigation__left-icons {
  display: flex;
  justify-content: flex-start;
}

.mobile-navigation__right-icons {
  .account-link-icon {
    padding-right: $spacing-sm;

    [dir="rtl"] & {
      padding-right: inherit;
      padding-left: $spacing-sm;
    }
  }
}

.mobile-navigation__cart__icon {
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;
}

.mobile-navigation {
  position: relative;
  width: calc(100% - #{$width-overlay-gap});
  height: 100%;
  background-color: $white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
}

.mobile-navigation__icon {
  position: absolute;
  top: 50%;
  right: $spacing-sm;
  transform: translateY(-50%);
  pointer-events: none;

  [dir="rtl"] & {
    right: auto;
    left: $spacing-sm;
    transform: translateY(-50%) rotate(180deg);
  }
}

.mobile-navigation__primary-menu {
  padding: $spacing-xs 0 !important; // important needed to override RTL
}

.mobile-navigation__primary-item + .mobile-navigation__primary-item {
  margin-top: $spacing-xs;
}

.mobile-navigation__sub-menu {
  position: fixed;
  top: 0;
  width: calc(100% - #{$width-overlay-gap});
  overflow: auto;
  height: 100%;
  background-color: white;
  display: none;
}

.mobile-navigation__sub-menu--active {
  display: block;
  z-index: 1;
}

.mobile-navigation__sub-menu__heading {
  position: relative;
  margin-bottom: 8px;
  padding: 28px $spacing-lg 28px $spacing-md;
  border-bottom: 1px solid $grey2;
  font-weight: bold;
  cursor: pointer;

  [dir="rtl"] & {
    padding-left: $spacing-lg;
    padding-right: $spacing-md;
  }
}

.mobile-navigation__expanded-item {
  padding: 0 0 $spacing-xs $spacing-md;
  border-bottom: 1px solid $grey2;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: $spacing-md;
  }
}

.mobile-navigation__expanded-item__heading {
  padding: 20px 0;
  font-weight: bold;
}

.mobile_navigation__item__text {
  position: relative;
  display: block;
  padding: 16px $spacing-lg 16px $spacing-md;

  [dir="rtl"] & {
    padding-left: $spacing-lg;
    padding-right: $spacing-md;
  }
}

// iOS devices require a cursor pointer in order to bubble up click events
.mobile_navigation__item--clickable {
  cursor: pointer;
}

// We dont want the intl-text spans to be the target of our Javascript events
.mobile-navigation .intl-text {
  pointer-events: none;
}

.mobile-navigation__link {
  &,
  &:hover {
    text-decoration: none;
  }
}

.mobile-navigation__secondary {
  padding: $spacing-xs 0;
  border-top: 1px solid $grey2;
}

.mobile-navigation__secondary-item__text {
  display: block;
  padding: 16px $spacing-md;
}
