@keyframes slide-in-vertical {
  100% { transform: translateY(0%); }
}

@keyframes slide-out-bottom {
  0% { transform: translateY(0%); }
  100% { transform: translateY(200%); }
}

@keyframes slide-out-top {
  0% { transform: translateY(0%); }
  100% { transform: translateY(-200%); }
}

@keyframes slide-in-horizontal {
  100% { transform: translateX(0%); }
}

@keyframes slide-out-right {
  0% { transform: translateX(0%); }
  100% { transform: translateX(200%); }
}

@keyframes slide-out-left {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-200%); }
}

@keyframes shake {
  10% { transform: translate3d(-1px, 0, 0); }
  20% { transform: translate3d(2px, 0, 0); }
  30% { transform: translate3d(-4px, 0, 0); }
  40% { transform: translate3d(4px, 0, 0); }
  50% { transform: translate3d(-4px, 0, 0); }
  60% { transform: translate3d(4px, 0, 0); }
  70% { transform: translate3d(-4px, 0, 0); }
  80% { transform: translate3d(2px, 0, 0); }
  90% { transform: translate3d(-1px, 0, 0); }
}

@mixin animation-top-slide-down {
  &-enter {
    transform: translateY(-200%);
  }

  &-enter-active {
    animation: slide-in-vertical 0.5s forwards;
  }

  &-exit-active {
    animation: slide-out-top 0.5s forwards;
  }
}

@mixin animation-bottom-slide-up {
  &-enter {
    transform: translateY(200%);
  }

  &-enter-active {
    animation: slide-in-vertical 0.5s forwards;
  }

  &-exit-active {
    animation: slide-out-bottom 0.5s forwards;
  }
}

@mixin animation-top-slide-down {
  &-enter {
    transform: translateY(-200%);
  }

  &-enter-active {
    animation: slide-in-vertical 0.5s forwards;
  }

  &-exit-active {
    animation: slide-out-top 0.5s forwards;
  }
}

@mixin animation-right-slide-in {
  &-enter {
    transform: translateX(200%);

    .rtl-direction & {
      transform: translateX(-200%);
    }
  }

  &-enter-active {
    animation: slide-in-horizontal 0.5s forwards;
  }

  &-exit-active {
    animation: slide-out-right 0.5s forwards;

    .rtl-direction & {
      animation: slide-out-left 0.5s forwards;
    }
  }
}
