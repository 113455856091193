.product-tile {
  .rtl-direction & {
    direction: rtl;
  }
}

.product-tile__link {
  text-decoration: none;
  display: block;
}

.product-tile__name {
  overflow-wrap: break-word;
  margin: 8px 0 0;

  :focus > & {
    @include focus-visible-outline();
  }

  :focus:not([data-focus-visible-added]) > & {
    outline: none;
  }
}

.product-tile__presentation {
  padding-right: 6px;
}

.product-tile__price {
  margin-top: 4px;
}

.product-tile__call-to-action {
  margin-top: 8px;
}
