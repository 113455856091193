@import '../../core';

.nicotine-warning {
  @include DEPRECATED_flexbox(row, space-around, center);

  background-color: $black;
  color: $white;
  border: 10px solid $white;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1.33;
  min-height: 20vh;
  padding: 20px;
  text-align: center;
  width: 100%;
  z-index: zindex(nicotine-warning);

  @include breakpoint(tablet) {
    font-size: 34px;
    line-height: 1.11;
  }
}

.nicotine-warning--canada {
  display: block;
  padding: 0;
  line-height: 1;
  border: 0;
}

.nicotine-warning__image {
  display: block;
  width: 100%;
}
