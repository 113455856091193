.line-item-list {
  padding: 0;
  flex: 1 1 auto;

  @include breakpoint(tablet) {
    padding: 24px;
    border: 1px solid $grey2;
  }
}

.line-item-list__heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 16px;

  &:not(:first-child) {
    margin-top: 36px;
  }
}

.line-item-list__heading {
  margin: 0;
}

.line-item-list__heading--auto-ship {
  color: $autoship-green;
}

.line-item-list__subscription-frequency {
  margin: -8px 0 32px; // negative margin because we cannot override the heading
}
