@import '../core';

$mini-header-offset-y: 20px;

.snackbar {
  position: fixed;
  top: $header-nav-xs-height - $mini-header-offset-y;
  left: 0;
  right: 0;
  padding: $spacing-xs $spacing-sm;
  background-color: $grey5;
  color: $white;
  text-align: center;

  @include breakpoint(tablet) {
    top: $header-logo-nav-sm-height;
  }

  &--error {
    background-color: $error-red;
  }

  &--entering {
    animation: 0.2s ease-out slideInDown forwards;
  }

  &--leaving {
    animation: 0.2s ease-out slideInDown reverse backwards;
  }
}

@keyframes slideInDown {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(-100%);
  }
}
