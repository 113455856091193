@import '../core';

.announcement-notification {
  position: relative;
  width: 100%;
  background: $black;
  color: $white;
  padding: 24px 0;
  transition: max-height 300ms ease-out, padding 300ms ease-out;

  &.exit {
    max-height: 100vh;
  }

  &.exit-active {
    max-height: 0;
    padding: 0;
  }
}

.announcement-notification__heading {
  color: $white;
  margin: 0 0 4px;
}

.announcement-notification__link {
  margin: 12px 0 6px;
}

.announcement-notification__caption {
  color: $grey3;
}

.announcement-notification__dismiss {
  position: absolute;
  top: 14px;
  right: 14px;
  font-size: 0;
  cursor: pointer;
}

.announcement-notification__dismiss_icon {
  filter: invert(1) brightness(100);
}
