$width-variant-actions-add-to-cart-buttton: 288px;

.variant-actions-form {
  width: 100%;
  margin-top: $spacing-md;

  @include breakpoint(tablet) {
    width: $width-variant-actions-add-to-cart-buttton;
  }
}

.variant-actions-form__quantity-and-price {
  display: flex;
  align-items: flex-end;
}

.variant-actions-form__quantity {
  flex-grow: 1;
}

.variant-actions-form__quantity__input {
  width: 100%;
}

.variant-actions-form__price {
  margin: 0 0 0 $spacing-sm;
  text-align: right;

  .rtl-direction & {
    margin: 0 $spacing-sm 0 0;
  }

  &:not(.variant-actions-form__price--quick-shop-modal) {
    display: none;
  }
}

.variant-actions-form__button {
  margin-top: $spacing-xs;
  width: 100%;
}

.variant-actions-form__button--quick-shop-modal {
  width: 100%;
}

// Some of the pages use levels of nesting that we cannot modify
.variant-actions-form__button button {
  max-width: 100%; // override .ProductCell__Button
  margin-top: 0; // override .ProductCell__Button
}

.variant-actions-form__button.subscribe {
  background-color: $autoship-green;
  border: $autoship-green;
}
