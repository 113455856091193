.frequently-asked-questions__content {
  margin-bottom: 32px;
}

.faq-item {
  border: 1px solid $grey2;
  padding: 0 $spacing-md;

  & + & {
    border-top: 0;
  }
}

.faq-item__question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-md 0;
  margin: 0;

  .faq-item--gray &,
  .faq-item--light & {
    color: $white;
  }
}

.faq-item__icon {
  flex-shrink: 0;

  .faq-item--light & path {
    fill: $white;
  }

  .faq-item--gray & path {
    fill: $lightThemeText;
  }
}

.faq-item__answer {
  border-top: 1px solid $grey2;
  margin: 0;
  padding: 24px 0;

  .faq-item--light & {
    color: $white;
  }

  .faq-item--gray & {
    color: $lightThemeText;
  }
}
