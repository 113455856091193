@import '../core';

$transition-time: 800ms;
$transition-right-md: $spacing-md / 2;
$transition-right-xl: $spacing-xl / 2;
$transition-right-xxl: $spacing-xxl / 2;
$transition-right-xxxxl: $spacing-xxxxl / 2;

.primary-notification-corner-box {
  display: flex;
  position: fixed;
  z-index: 5;
  bottom: 65px;
  right: $transition-right-md;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background: $autoship-green;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  min-height: 48px;
  min-width: 48px;
  padding-right: 28px;
  cursor: pointer;
  transition: right $transition-time;

  &.transition-disabled {
    transition: none;
  }

  &.appear-active,
  &.appear-done {
    right: $transition-right-md;
  }

  @include breakpoint(tablet) {
    bottom: 40px;

    &.appear-active,
    &.appear-done {
      right: $transition-right-xl;
    }
  }

  @include breakpoint(desktop) {
    &.appear-active,
    &.appear-done {
      right: $transition-right-xxl;
    }
  }

  @include breakpoint(desktop-xlarge) {
    &.appear-active,
    &.appear-done {
      right: $transition-right-xxxxl;
    }
  }

  @media only screen and (min-width: $layout-max-width + 1) {
    $half-outer-margin: calc((((100vw - #{$layout-max-width}) / 2) + #{$spacing-xxxxl}) / 2);

    &.appear-active,
    &.appear-done {
      right: $half-outer-margin;
    }
  }
}

.primary-notification-corner-box__content {
  flex: 1 1 auto;
  color: $white;
  max-width: 323px;
  margin-right: -1400px;
  margin-bottom: -500px;
  opacity: 0;
  overflow: hidden;
  transition:
    margin-right $transition-time $transition-time/2,
    margin-bottom $transition-time $transition-time/2,
    opacity $transition-time;

  > * {
    overflow: hidden;
    padding: 12px;
  }

  > * + * {
    padding-top: 0;
    margin: 0;
    margin-top: -4px;
  }

  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done {
    margin-right: 0;
    margin-bottom: 0;
    opacity: 1;
    transition:
      margin-right $transition-time,
      margin-bottom $transition-time,
      opacity $transition-time $transition-time/2;
  }

  &.appear-active.transition-disabled,
  &.appear-done.transition-disabled,
  &.enter-active.transition-disabled,
  &.enter-done.transition-disabled {
    transition: none;
  }
}

.primary-notification-corner-box__content p {
  line-height: 18px;
}

.primary-notification-corner-box__content p.markdown-content {
  margin: 6px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.primary-notification-corner-box__toggle {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  outline: 0;
  padding: 0;
  background: none;
  border: 0;
  appearance: none;
  font-size: 0;
  cursor: pointer;
  filter: invert(1) brightness(100);

  img {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.primary-notification-corner-box__icon-promotions {
  opacity: 1;
  transition: opacity $transition-time / 2 $transition-time / 2;

  .primary-notification-corner-box__toggle.enter-active &,
  .primary-notification-corner-box__toggle.enter-done & {
    transition: opacity $transition-time / 2;
    opacity: 0;

    &.transition-disabled {
      transition: none;
    }
  }
}

.primary-notification-corner-box__icon-collapse {
  opacity: 0;
  transition: opacity $transition-time;

  .primary-notification-corner-box__toggle.enter-active &,
  .primary-notification-corner-box__toggle.enter-done & {
    transition: opacity $transition-time / 2 $transition-time / 2;
    opacity: 1;

    &.transition-disabled {
      transition: none;
    }
  }
}
