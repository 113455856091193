.page-hero__content-align--left {
  text-align: left;
}

.page-hero__content-align--center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.page-hero__content-wrapper {
  .page-hero__content {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}

.page-hero__hide-paragraph-mobile {
  p,
  h4,
  h5,
  h6 {
    @include breakpoint(mobile) {
      display: none;
    }
  }
}

.page-hero__content {
  color: $black;

  > * {
    color: inherit;
  }

  .page-hero__content-font--light & {
    color: $white;
  }

  .page-hero__content-font--gray & {
    color: $lightThemeText;

    h1,
    h2,
    h3 {
      color: $white;
    }
  }
}
