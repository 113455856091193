@import '../core';

.page-section {
  padding: 64px 32px;

  @include breakpoint(tablet) {
    padding: 0 8.33%;
  }
}

.page-section__content {
  max-width: 1280px;
  margin: 0 auto;
}

.page-section__title {
  margin-bottom: 34px;
}

.contentful-content {
  margin: 60px auto;
}
