html body .yotpo {
  div,
  span {
    font-family: inherit;
  }

  .yotpo-regular-box {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  .yotpo-bottomline-2-boxes {
    border: none;
  }

  .yotpo-main .content-title {
    font-size: 24px;
    line-height: 30px;
  }

  .yotpo-label {
    display: none;
  }

  .yotpo-bottomline-2-boxes {
    height: auto !important;
  }

  .yotpo-review {
    flex-direction: column;
    padding: 20px 0;

    @include breakpoint(tablet) {
      flex-direction: row;
      padding: 85px 0;
    }

    .content-review {
      font-size: 18px;
      line-height: 24px;
    }

    .yotpo-tool-tip {
      width: 200px;
    }

    .yotpo-review-wrapper {
      margin-bottom: 16px;
    }

    .yotpo-header {
      align-items: center;
      display: flex;

      @include breakpoint(tablet) {
        align-items: flex-right;
        flex-direction: column;
        margin-right: cols(1);
        width: cols(3);
      }

      .yotpo-header-element {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(tablet) {
          align-items: flex-end;
          flex-direction: column;
        }

        .y-label.yotpo-user-name {
          color: $black;
          font-size: 18px;
          line-height: 24px;
          margin: 0;

          @include breakpoint(tablet) {
            order: 2;
          }
        }

        .yotpo-review-stars {
          align-items: center;
          display: flex;
          margin: 0 0 0 16px;

          @include breakpoint(tablet) {
            margin: 0;
            order: 1;
          }
        }

        .label-with-tooltip {
          order: 3;
          width: 100%;

          @include breakpoint(tablet) {
            width: auto;
          }
        }

        .yotpo-user-title {
          font-size: 11px;
        }

        .yotpo-clr {
          display: none;
        }

        .yotpo-review-date {
          margin-left: 35px;
        }
      }

      .yotpo-icon-profile {
        display: none;
      }
    }

    .yotpo-main {
      margin: 16px 0 0;

      @include breakpoint(tablet) {
        margin: 0;
        margin-right: cols(2);
        width: cols(6);
      }
    }

    .yotpo-footer {
      display: flex;

      @include breakpoint(tablet) {
        margin-left: cols(4);
        width: cols(8);
      }

      .yotpo-icon-seperator {
        display: none;
      }

      .yotpo-helpful {
        order: 1;

        .label-helpful {
          color: $grey3;
        }
      }

      .footer-actions {
        flex: 1;
        order: 2;
        text-align: right;
      }
    }
  }

  &.yotpo-main-widget .yotpo-comments-box {
    border: none;
    margin: 0 !important;

    @include breakpoint(tablet) {
      margin: 24px 0 0 cols(4) !important;

      .yotpo-header {
        align-items: flex-start;
        width: 100%;
      }
    }

    .yotpo-header-actions {
      display: none;
    }

    .yotpo-header-element label {
      display: none;
    }

    .yotpo-user-name {
      display: none;
    }

    .stars-and-name::before {
      content: "JUUL's reply";
    }

    .yotpo-main {
      margin: 0;
      width: 100%;
    }

    .yotpo-comment {
      background: $DEPRECATED_pale-grey;
      margin: 0 !important;
      padding: 16px;
    }

    .yotpo-footer {
      display: none;
    }
  }
}

.yotpo-stars {
  display: flex;
}

// we must override yotpo's widget specificity (which is *extremely* high at approx. 13 points)
// therefore, !important is used
.yotpo-icon-star,
.yotpo-icon-half-star,
.yotpo-icon-empty-star {
  color: $autoship-green !important;
}

.yotpo
.yotpo-icon {
  font-size: 14px !important;
  letter-spacing: 3px;
}
