@mixin breakpoint($media) {
  @if $media == mobile {
    @media only screen and (max-width: $screen-sm-min - 1) {
      @content;
    }
  }

  @else if $media == tablet {
    @media only screen and (min-width: $screen-sm-min) {
      @content;
    }
  }

  @else if $media == desktop {
    @media only screen and (min-width: $screen-md-min) {
      @content;
    }
  }

  @else if $media == desktop-large {
    @media only screen and (min-width: $screen-lg-min) {
      @content;
    }
  }

  @else if $media == desktop-xlarge {
    @media only screen and (min-width: $screen-xlg-min) {
      @content;
    }
  }

  @else if $media == desktop-xxlarge {
    @media only screen and (min-width: $screen-xxlg-min) {
      @content;
    }
  }
}

@mixin isIE11() {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin link-states {
  &,
  &:active,
  &:visited {
    @content;
  }
}

@mixin display($value) {
  html & { display: $value; }
}

@mixin position-absolute($params: ()) {
  $params: map-merge(
    (
      top: auto,
      right: auto,
      bottom: auto,
      left: auto,
      width: auto,
      height: auto,
    ),
    $params
  );

  inset: map-get($params, top) map-get($params, right) map-get($params, bottom) map-get($params, left);
  height: map-get($params, height);
  position: absolute;
  width: map-get($params, width);
}

/// Responsive font sizing. The size of the font will scale with the viewport
/// based on the information that you provide it. Additional properties may be
/// adding for things like padding.
///
/// @param {Integer} $font-size-min
/// @param {Integer} $font-size-max
/// @param {String} $properties [font-size]
/// @param {Integer} $vw-min [320] - the minimum viewport width
/// @param {Integer} $vw-max [1280] - the maximum viewport width
///
/// @require {function} strip-unit
///
/// @example scss - Basic usage Sass
///   h1 {
///     @include fluid-type(28px, 52px);
///   }
///
/// @example scss - Basic usage CSS output
///   h1 {
///     font-size: 28px;
///   }
///
///   @media screen and (min-width: 320px) {
///     h1 {
///       font-size: calc(28px + 24 * (100vw - 320px) / 960);
///     }
///   }
///
///   @media screen and (min-width: 1280px) {
///     h1 {
///       font-size: 52px;
///     }
///   }
///
/// Concept of precise control from Mike Riethmuller
/// https://madebymike.com.au/writing/precise-control-responsive-typography/
///
/// Original mixin implementation from Indrek Paas
/// http://www.sassmeister.com/gist/7f22e44ace49b5124eec
@mixin fluid-type(
  $font-size-min,
  $font-size-max,
  $properties: font-size,
  $vw-min: $screen-xs-min,
  $vw-max: $screen-lg-min
) {
  @if(
    unitless($font-size-min) or
    unitless($font-size-max) or
    unitless($vw-min) or
    unitless($vw-max)
  ) {
    @error "All min and max properties must be provided with a unit (e.g.: px)";
  }

  @if(
    unit($font-size-min) == unit($font-size-max) and
    unit($font-size-min) == unit($vw-min) and
    unit($font-size-min) == unit($vw-max)
  ) {
    @each $property in $properties {
      #{$property}: $font-size-min;
    }

    @media screen and (min-width: $vw-min) {
      @each $property in $properties {
        #{$property}: calc(#{$font-size-min} + #{strip-unit($font-size-max - $font-size-min)} * (100vw - #{$vw-min}) / #{strip-unit($vw-max - $vw-min)});
      }
    }

    @media screen and (min-width: $vw-max) {
      @each $property in $properties {
        #{$property}: $font-size-max;
      }
    }
  }

  @else {
    @error "All units must be the same. Incompatible units were passed: #{unit($font-size-min)}, #{unit($font-size-max)}, #{unit($vw-min)}, #{unit($vw-max)}";
  }
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
///
/// Original implementation by Miriam Suzanne
/// http://stackoverflow.com/a/12335841/1339786
///
/// Update implementation from Hugo Giraudel
/// https://css-tricks.com/snippets/sass/strip-unit-function/
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin placeholder-text {
  color: $grey3;
  font-size: 14px;
  line-height: inherit;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 1;
}

@mixin clear-floated-children {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin focus-visible-outline {
  outline: 2px solid $blue-base-100;
  outline-offset: 2px;
}
