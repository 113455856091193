@import '../core';

.Modal__content--country-selector {
  @include breakpoint(tablet) {
    width: 480px;
  }
}

.Modal__body--country-selector {
  text-align: center;
}

.country-selector__header {
  margin-bottom: 8px;
}

.country-selector__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.country-selector__item {
  width: calc(50% - 12px);
  padding: 0;
  border: 1px solid $grey2;
  background: none;
  cursor: pointer;
}

.country-selector__flag {
  display: block;
  width: 100%; // IE 11
}
