.media-with-text-entry__video-element {
  max-width: 100%;
}

.media-with-text-entry__video--desktop,
.media-with-text-entry__video--mobile {
  display: none;
}

@include breakpoint(mobile) {
  .media-with-text-entry__video--mobile {
    display: block;
  }
}

// 'tablet' means tablet and up
@include breakpoint(tablet) {
  .media-with-text-entry__video--desktop {
    display: block;
  }
}
