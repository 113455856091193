.entry-layout--full-bleed-with-padding {
  @include fluid-type(
    32px,
    80px,
    $properties: ('padding-top', 'padding-bottom')
  );
}

.entry-layout--constrained-with-padding,
.entry-layout--constrained-without-padding {
  @include fluid-type(
    32px,
    80px,
    $properties: ('margin-top', 'margin-bottom')
  );
}

.rtl-direction .entry-layout__wrapper {
  direction: rtl;
}

.entry-layout__wrapper--with-padding {
  padding: 24px;
}
