.header__quick-links__items {
  display: flex;
}

.header__quick-links__items--secondary {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.header__quick-links__item {
  align-items: center;
  border-right: 0.5px solid $grey2;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 12px;
  text-transform: uppercase;

  .header__quick-links__items > &:first-child {
    padding-left: calc(12px - 0.5rem);
  }

  .header__quick-links__items--secondary > &:first-child {
    border-left: 0.5px solid $grey2;
  }

  .header__quick-links__items--secondary > &:last-child {
    border-right: 0;
  }

  @include breakpoint(desktop) {
    padding: 0 20px;

    .header__quick-links__items > &:first-child {
      padding-left: calc(20px - 0.5rem);
    }
  }

  > svg {
    flex-shrink: 0;
  }
}

.header__quick-link-icon {
  height: 16px;
  position: relative;
  width: 12px;
}

.header__quick-links__item__name {
  @include breakpoint(tablet) {
    margin-left: 8px;
    padding-top: 3px;
  }
}

.header__quick-links__item--language {
  position: relative;
}
