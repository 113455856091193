@import '../core';
@import './membership-banner';
@import './product-gallery';
@import './product-hero';
@import './collection';
@import './catalog';
@import './form';
@import './information';
@import './warning';

.product-page--single__section {
  @include fluid-type($spacing-lg, $spacing-xxl, $properties: ('margin-top', 'padding-top'));

  width: 100%;
  border-top: 1px solid $grey2;
}

.product-page--single__section__content {
  @include breakpoint(mobile) {
    & + & {
      margin-top: $spacing-lg;
    }
  }
}

.product-page--single__all-link--form-header {
  display: inline-block;
  margin-bottom: $spacing-sm;
  color: $black;
}

.product-page--single__hero {
  padding-top: 0;
  border-top: 0;

  @include breakpoint(mobile) {
    margin-top: 0;
  }
}

.product-page__hero-image {
  @include breakpoint(mobile) {
    // Negative margins here to make the image span the entire viewport
    margin-left: -24px;
    margin-right: -24px;
  }
}

.product-page__reviews__disclaimer {
  margin: 0 auto;
  max-width: 800px;
  padding: $spacing-md;
  text-align: center;
}

.product-page__box-contents__image__container {
  text-align: center;
}

.product-page__box-contents__image {
  width: auto;
  max-height: 345px;
}

.product-page__box-contents--single {
  @include breakpoint(tablet) {
    text-align: center;
  }
}

.product-page__box-contents__title {
  padding: 0 $spacing-md;
  text-align: center;

  @include breakpoint(tablet) {
    padding: 0;
  }
}

.product-text-block {
  background: $white;
  padding: $spacing-md 0;

  @include breakpoint(tablet) {
    padding: $spacing-xxl 0;
  }
}

.product-page__description {
  white-space: pre-line;
}

.product-page__description:empty {
  display: none;
}

.product-page__faq {
  background: $white;
}

.ProductFooterWrapper {
  flex: 1;
  padding-right: $spacing-md;
}

.product-page__free-shipping-message {
  border-bottom: solid 1px $grey2;
  padding: $spacing-sm 0;
  width: 100%;
}

.product-page__free-shipping-message__condition {
  margin-top: $spacing-xs;
}

.product-page__nicotine-strength-message-container {
  width: 100%;
}

.product-page__nicotine-strength-message {
  border-bottom: solid 1px $grey2;
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;

  > div {
    display: inline-block;
  }

  @include breakpoint(tablet) {
    border: none;
    padding: 0;
  }
}

.ProductFooter__button__container {
  width: 65%;
}

.ProductFooter__button__container--full-width {
  width: 100%;
}

.product__banner-image {
  object-fit: cover;
  object-position: bottom;
}

.product-page__add-to-cart-options .is-commerce:not(:first-child) button {
  margin-top: $spacing-sm;
  width: 100%;

  @include breakpoint(tablet) {
    width: auto;
  }
}
