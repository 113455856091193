@import '@viewlio/juulio-bridge/styles/common.scss';
@import '@viewlio/juulio-bridge/styles/pages/product_display_page/community-content-gallery.scss';
@import '@viewlio/juulio-bridge/styles/locator/locator_v2.scss';

@import '@viewlio/juulio-bridge/styles/core/_colors.scss';
@import '@viewlio/juulio-bridge/styles/core/_functions.scss';
@import '@viewlio/juulio-bridge/styles/core/_icons.scss';
@import '@viewlio/juulio-bridge/styles/core/_mixins.scss';
@import '@viewlio/juulio-bridge/styles/core/_variables.scss';
@import '@viewlio/juulio-bridge/styles/build_your_plan/pods';
@import '@viewlio/juulio-bridge/styles/build_your_plan/review';
@import '@viewlio/juulio-bridge/styles/build_your_plan/footer';
@import '@viewlio/juulio-bridge/styles/build_your_plan/removed_from_cart_modal';
@import '@viewlio/juulio-bridge/styles/application/cookie-policy';
@import 'swiper/swiper.scss';
@import 'swiper/modules/pagination.scss';
@import 'swiper/modules/effect-fade.scss';

// reset some of juulio-bridge global styles if needed
@import './reset.scss';

@import './yoti.scss';

@font-face {
  font-family: $dogpatch;
  font-weight: 300 800;
  font-style: normal;
  font-display: swap;
  src:
    url('/assets/viewlio/fonts/dogpatch-latin.woff2') format('woff2'),
    url('/assets/viewlio/fonts/dogpatch-latin.woff') format('woff');
}
