.select-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  min-height: 52px;
  height: 51px; // IE11 workaround for align-items: center with min-height
  width: 100%;
  border: 1px solid $grey2;
  border-radius: 2px;

  &:not(&--disabled):hover {
    border-color: $grey3;
  }

  &--focus:not(&--disabled) {
    border-color: $black;
  }

  .rtl-direction & {
    direction: rtl;
  }
}

.select-input--disabled {
  background-color: $grey2;
}

.select-input--error {
  border-color: $error-red;
}

.select-input__text {
  .select-input--with-float & {
    align-self: flex-end;
    padding-bottom: 7px;
  }
}

.select-input__element {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background: red;
  opacity: 0;

  .select-input--disabled & {
    cursor: not-allowed;
  }
}

.select-input__label {
  margin: 0;
  color: $grey3;
  transition: all 0.03s ease-out;
}

.select-input__label--float {
  position: absolute;
  top: 1px;
  padding: 8px 0 0;
  background-color: $white;
}

.select-input__icon {
  pointer-events: none; // So clicks go through to the select element
}

.select-input__error {
  margin-top: 5px;
}
