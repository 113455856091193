.text-entry--long-line-length {
  @include breakpoint(desktop) {
    // Equivalent of 10 columns in a 12-grid layout to match design
    max-width: percentage(10 / 12);
  }
}

.text-entry--short-line-length {
  @include breakpoint(tablet) {
    max-width: 50%;
  }
}

.text-entry--aligned-center {
  @include breakpoint(tablet) {
    text-align: center;
    margin: 0 auto;
  }
}

// On tablet and above breakpoints our call to actions use Flexbox so we can't
// rely on our text-align attribute taking effect.
.text-entry--aligned-center .entry-content__call-to-actions {
  align-items: center;
}
