@import '../core';

.mini-header {
  display: none;

  .header__quick-links__item--language {
    height: $header-logo-nav-sm-height;

    .store-locale-selector__dropdown {
      top: $header-logo-nav-sm-height;
    }
  }
}

.body--mini-header {
  background-color: $grey1;

  .mini-header {
    display: block;
  }

  // Modifications to other elements.

  .header,
  .desktop-navigation__container,
  .mobile-navigation__container {
    display: none;
  }

  .body__content {
    background-image: none;
    padding-top: $header-nav-xs-height;
  }

  .body__content.no-padding {
    padding-top: 0;
  }

  .FlashMessages {
    top: $header-nav-xs-height;

    @include breakpoint(tablet) {
      top: $header-logo-nav-sm-height;
    }
  }
}

.mini-header__cart {
  border-left: 1px solid $DEPRECATED_light-blue-grey;
  display: flex;
  justify-content: center;

  @include breakpoint(tablet) {
    border-left: none;
    display: none;
  }
}

.mini-header__cart .header__cart {
  border-left: none;
}

.mini-header__juul-logo {
  border-right: 1px solid $DEPRECATED_light-blue-grey;

  @include DEPRECATED_flexbox(row, center, center);

  @include breakpoint(tablet) {
    border-right: none;
    justify-content: flex-start;
    padding-left: 10px;
  }
}

.mini-header__title-wrapper {
  align-items: center;
  display: flex;
  padding: 20.5px 0;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
}
