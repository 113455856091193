$tag-small-top-padding: 3px;
$tag-spacing: 5px;

@mixin type($bgColor, $fontColor: $white) {
  color: $fontColor;
  background-color: $bgColor;
  border-color: $bgColor;
}

.tag {
  border: 1px solid $grey2;
  border-radius: 2px;
  display: inline-block;
  padding: $tag-small-top-padding $spacing-xs;

  &--big {
    padding: $spacing-xs $spacing-sm;
  }

  &--success {
    @include type($autoship-green);
  }

  &--danger {
    @include type($error-red);
  }

  &--secondary {
    @include type($grey1, $black);
  }

  &--dark {
    @include type($black);
  }

  &--full-width {
    width: 100%;
  }

  & + .tag {
    margin-left: $tag-spacing;

    .rtl-direction & {
      margin-left: 0;
      margin-right: $tag-spacing;
    }
  }
}
