@import '../core';

a,
button,
input[type='checkbox'],
input[type='submit'],
video {
  &:focus {
    @include focus-visible-outline();
  }
}

// https://github.com/WICG/focus-visible#2-update-your-css
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}
