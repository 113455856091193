.entry-content--gray {
  color: $lightThemeText;

  > * {
    color: inherit;
  }

  .entry-content__heading,
  .entry-content__subheading {
    color: $white;
  }
}

.entry-content--light {
  color: $white;

  > * {
    color: inherit;
  }
}

.entry-content__subheading {
  margin: 0;
}

.entry-content__heading {
  margin: 0;

  h3 + & {
    margin: 8px 0 0;
  }
}

.entry-content__body {
  h2 + &,
  h3 ~ & {
    margin: 16px 0 0;
  }

  // Because this content is rendered via Markdown we need to provide some extra
  // specificity for the light font theme option to handle headings.
  .entry-content--gray & > *,
  .entry-content--light & > * {
    color: inherit;
  }
}

.entry-content__call-to-actions {
  margin-top: 24px;

  @include breakpoint(tablet) {
    display: flex;
    flex-direction: column;
  }
}

.entry-content__call-to-action {
  & + & {
    margin-top: 8px;
  }

  @include breakpoint(tablet) {
    max-width: 240px;
  }
}
