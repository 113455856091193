.product-page__variant-list {
  display: flex;
  flex-wrap: wrap;
}

.product-selector__limited-product-text {
  border-bottom: solid 1px $grey2;
  padding-top: 20px;
  padding-bottom: 20px;

  @include breakpoint(tablet) {
    border-bottom: none;
    display: inline-block;
    max-width: 360px;
  }
}

.ProductCell {
  background: $white;
  border-bottom: solid 1px $grey2;
  display: block;
  min-height: 126px;
  padding: 0;
  position: relative;

  @include breakpoint(tablet) {
    background: $white;
    border: solid 1px $grey2;
    display: inline-block;
    float: left;
    height: auto;
    margin: -0.5px;
    padding: 30px 24px;
    text-align: center;
  }
}

.ProductCell--tall {
  @include breakpoint(tablet) {
    padding: 50px 0 40px;
  }
}

.ProductCell__Content,
.ProductCell__Content--link {
  display: inline-block;
  height: 100%;
  padding: 20px 0 20px 10px;
  position: relative;
  width: 80%;

  @include breakpoint(tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.2;
    padding: 0;
    width: 100%;

    * {
      // Fixes a bug in IE 10 and 11 where flex items with align-items: center;
      // and flex-direction: column; can overflow their container.
      // https://github.com/philipwalton/flexbugs#flexbug-2
      max-width: 100%;
    }
  }

  .star-rating--no-reviews {
    display: inline;
    visibility: hidden;
  }
}

.ProductCell_Title__Link {
  text-decoration: none;
}

.ProductCell__Content--link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @include breakpoint(tablet) {
    display: none;
  }
}

.ProductCell_Title {
  @include breakpoint(tablet) {
    text-align: center;
  }
}

.ProductCell_Heading {
  margin: 0 0 2px;
}

.ProductCell_Title--no-nicotine-info {
  margin-bottom: $spacing-xs;
}

.ProductCell__special-edition-container {
  font-size: 14px;
  margin-bottom: 8px;
  min-height: 12px;

  @include breakpoint(tablet) {
    margin-bottom: 4px;
    min-height: 15px;
  }
}

.ProductCell__special-edition-container > {
  margin-top: -5px;

  @include breakpoint(tablet) {
    margin: 0;
  }
}

.ProductCell__price--discount {
  text-decoration: line-through;
}

.ProductCell_Title__price {
  font-weight: normal;

  @include breakpoint(tablet) {
    margin-left: 0;
  }
}

.ProductCell__image-container {
  display: none;

  @include breakpoint(tablet) {
    display: block;
    margin: 5px 15px 8px;
  }
}

.ProductCell__image {
  display: none;

  @include breakpoint(tablet) {
    display: block;
    float: none;
    height: 140px;
    width: auto;
  }
}

.ProductCell__image--tall {
  @include breakpoint(tablet) {
    height: auto;
    margin: 15px 0;
    width: 117px;
  }
}

.ProductCell__Icon {
  float: left;
  height: 100%;
  width: 20%;

  @include DEPRECATED_flexbox(row, initial, center);

  @include breakpoint(tablet) {
    display: none;
  }
}

.ProductCell__Icon img {
  height: 100px;
  width: auto;
}

a.ProductCell__Link {
  align-items: flex-end;
  display: inline-flex;
  height: 30px;
  text-decoration: underline;

  .ProductCell__Button--left + & {
    float: right;
  }

  @include breakpoint(tablet) {
    height: auto;
    margin-top: 16px;
  }
}

.ProductCell__Link__text {
  margin-top: 10px;
}

.ProductCell__Quantity {
  float: right;
  min-width: 80px;
  right: 0;
  top: 16px;
  width: fit-content;

  @include breakpoint(tablet) {
    margin-bottom: 16px;
    position: relative;
  }
}

.ProductCell__Quantity--sold-out {
  display: none;
}

.ProductCell__sold-out {
  align-items: flex-end;
  display: inline-flex;
  float: right;
  height: 30px;
}

.ProductCell_Button__container {
  width: fit-content;

  button {
    width: 100%;
  }

  @include breakpoint(tablet) {
    max-width: 178px;
    width: 55%;
  }
}

.ProductCell__Button--left,
.ProductCell__Button {
  margin-top: $spacing-sm;

  @include breakpoint(tablet) {
    max-width: 200px;
    width: 100%;
  }
}

.ProductCell__Button--left {
  float: left;

  @include breakpoint(tablet) {
    float: none;
  }
}

.ProductCell__Button--commitment {
  margin-top: 16px;
}

.ProductCell__divider {
  border: 0;
  border-top: 1px solid $DEPRECATED_light-blue-grey;
  height: 1px;
  width: 100%;

  @include breakpoint(tablet) {
    display: none;
  }
}

.ProductCell__Content .ProductCell__image,
.ProductCell__Content--link .ProductCell__image {
  transition: all 0.2s ease-in-out;
}

.ProductCell__Content:hover .ProductCell__image,
.ProductCell__Content--link:hover .ProductCell__image {
  transform: scale(1.05);
}
