@import '../core';

.Modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba($black, 0.7);
  inset: 0;
  height: 100%;
  position: fixed;
  width: 100vw;
  z-index: 5000;

  @include breakpoint(tablet) {
    justify-content: center;
  }
}

.Modal__content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $white;
  outline: none;

  @include breakpoint(tablet) {
    height: auto;
    max-height: 85vh;
    width: 395px;
  }
}

.Modal__content--closable {
  width: calc(100% - 40px);

  @include breakpoint(tablet) {
    width: 395px;
  }
}

.Modal__body {
  padding: 32px 24px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(mobile) {
    flex-grow: 1;
  }

  @include isIE11() {
    // IE 11 also needs the max height on this element to ensure it will not
    // overflow
    max-height: 85vh;
  }

  &::after {
    // Firefox does not support bottom padding on a scrollable element so we
    // need to use a pseudo-element workaround to mimic the padding behavior
    // seen in other browsers.
    //
    // https://bugzilla.mozilla.org/show_bug.cgi?id=748518
    content: '';
    display: block;
    height: 24px;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .Modal__header + & {
    padding-top: 24px;
  }
}

.Modal__actions {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $grey2;
  padding: 24px;

  * + * {
    margin-top: 8px;
  }

  @include breakpoint(tablet) {
    flex-direction: row-reverse;

    * + * {
      margin-top: auto;
      margin-right: 8px;
    }

    .rtl-direction & {
      * + * {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }

  .rtl-direction & {
    direction: rtl;
  }
}

.Modal__footer {
  background-color: $grey2;
  margin-top: -5px;
  padding: 10px 40px;
}

.Modal__closer-circle {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  transform: translate(-100%, 0);
  cursor: pointer;
  z-index: 1; // just to make sure it sits on top of other modal content

  @include breakpoint(tablet) {
    right: 0;
    left: auto;
    transform: translate(8px, -100%);

    .rtl-direction & {
      right: auto;
      left: 0;
      transform: translate(-10px, -100%);
    }
  }
}

.Modal__close-svg {
  display: block;

  path {
    fill: $white;
  }
}

.Modal__header {
  margin: 0;
  padding: 16px 24px;
  border-bottom: 1px solid $grey2;

  &.heading--small {
    font-size: 16px;
  }
}

.Modal__title {
  @extend .heading--medium;

  margin-bottom: 20px;

  @include breakpoint(tablet) {
    margin-bottom: 30px;
  }
}

.Modal__text {
  margin-bottom: 30px;
}

.Modal__button {
  display: block;
  margin: 0 auto 10px;
  min-width: 75%;

  &:only-child {
    margin-top: 30px;
  }

  &:last-child {
    margin-bottom: 120px;
  }

  @include breakpoint(tablet) {
    display: inline-block;
    margin: 0 10px 10px 0;
    min-width: auto;

    &:last-child {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
}

.hr--modal {
  height: 0;
  margin: 8px -24px;
  border-top: none;
  border-bottom-color: #f5f5f5;
}
