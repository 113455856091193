.build-your-plan__picker-content .content-wrapper {
  padding: 0;
}

.build-your-plan__picker-content .heading--large {
  margin: 0 0 16px;
}

.build-your-plan__picker-pod-tile--hidden {
  display: none;
}

.build-your-plan__subheading--green {
  color: $autoship-green;
  margin-bottom: 8px;
}

.build-your-plan__intro-copy {
  margin-bottom: 8px;
}

.build-your-plan__flavor-guide-link {
  text-decoration: underline;
  cursor: pointer;
}

.build-your-plan__nicotine-strength-wrapper {
  margin: 32px 0 16px;
  padding-top: 32px;
  border-top: 1px solid $grey1;
}

.build-your-plan__nicotine-strength-heading {
  margin-top: 0;
}

.build-your-plan__picker-content .product-listing {
  margin: 24px 0;
}

.build-your-plan__unavailable {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid $grey1;
}
