.juul-pass__modal-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.juul-pass__modal-link {
  margin-bottom: $spacing-sm;
}

.juul-pass__modal-body {
  text-align: left;
}

.juul-pass__modal {
  max-width: 396px;
}

.juul-pass__modal-title {
  margin-top: 0;
}
