.store-locale-selector {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
}

.store-locale-selector-inline {
  padding-bottom: 60px;

  .store-locale-selector-inline__item {
    border-right: 1px solid $grey5;
    padding: 0 10px;

    &:last-child {
      border-right: none;
    }
  }
}

.store-locale-selector--footer {
  position: relative;
  padding-top: $spacing-md;
  padding-bottom: $spacing-md;
  border-top: 1px solid $grey2;
  background-color: $black;
  color: $white;

  @include breakpoint(tablet) {
    display: none;
    border-top: 0;
    background-color: transparent;
  }

  &.show-on-desktop {
    @include breakpoint(tablet) {
      display: block;
      border-top: 1px solid $grey2;
      background-color: $black;
    }
  }
}

.store-locale-selector__dropdown--footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  border: 1px solid $grey2;
  border-left: 0;
  border-right: 0;
  width: 100%;
  background-color: $black;
  color: inherit;
  z-index: 9;
}

.store-locale-selector__dropdown--header {
  inset: 100% 0 auto auto;
  width: 190px;
  border: 1px solid $grey2;
  background-color: $white;
  position: absolute;
  border-left: 0;
  border-right: 0;
  color: inherit;
  z-index: 9;
}

.store-locale-selector__dropdown {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  border: 1px solid $grey2;
  border-left: 0;
  border-right: 0;
  width: 100%;
  background-color: $black;
  color: inherit;
  z-index: 9;

  @include breakpoint(tablet) {
    top: 100%;
    left: auto;
    bottom: auto;
    width: 190px;
    border: 1px solid $grey2;
    background-color: $white;
  }
}

$height-check-icon: 24px;
$vertical-padding-language: 12px;
$min-height-language: $height-check-icon + $vertical-padding-language * 2;

.store-locale-selector__dropdown-item {
  display: flex;
  align-items: center;
  min-height: $min-height-language;
  padding: $vertical-padding-language $spacing-md;
  text-decoration: none;

  & + & {
    border-top: 1px solid $grey2;
  }

  &:hover {
    background-color: $grey1;
  }

  // Fix for IE 11 not respecting align-items: center on an element that has a
  // min-height
  &::after {
    content: '';
    min-height: $height-check-icon;
  }
}

.locale-selector__check {
  margin-left: 5px;

  @include breakpoint(mobile) {
    filter: invert(100%);
  }
}

.store-locale-selector__current {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.store-locale-selector__flag {
  width: 24px;
  height: auto;
  margin-right: 8px;
}

.store-locale-selector__locale-name {
  text-transform: uppercase;
}

.store-locale-selector__flag + .store-locale-selector__locale-name {
  padding-top: 3px; // fixes the vertical alignment of our font
}
