.juul-pass__banner {
  margin-top: $spacing-xs;
  background-color: $grey1;
}

.juul-pass__banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-sm 0;

  @include breakpoint(tablet) {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.juul-pass__banner-text {
  margin-right: $spacing-md;
  text-align: center;
  padding-bottom: $spacing-sm;

  @include breakpoint(tablet) {
    text-align: left;
    padding-bottom: 0;
    padding-right: $spacing-sm;
  }

  p {
    margin: 0;
  }
}
