.translation_higlighter__highlight {
  background-color: yellow;
  color: black;
}

.translation_higlighter__link {
  background: blue;
  color: $white;
  display: block;
  padding: 5px;

  @include DEPRECATED_font-sizes(12px);
}

.translation_higlighter__contentful-highlight {
  border: 8px solid #3cb8e6;
  margin-bottom: 10px;
}

.translation_higlighter__contentful-link {
  @include DEPRECATED_font-sizes(16px);

  margin: 10px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  background: #ffd66a;
  color: #ef5c6c;
  padding: 5px;
  text-align: center;
  z-index: 9999;
}
