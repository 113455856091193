.video-entry__video {
  width: 100%;
}

.video-entry__video--desktop,
.video-entry__video--mobile {
  display: none;
}

@include breakpoint(mobile) {
  .video-entry__video--mobile {
    display: block;
  }
}

// 'tablet' means tablet and up
@include breakpoint(tablet) {
  .video-entry__video--desktop {
    display: block;
  }
}
