@import '../core';

html .Select {
  border: 1px solid $grey2;
  border-radius: 3px;
  height: $select-height;
  outline: none;
  padding: 5px;

  &:hover {
    border-color: $grey3;
  }

  &.is-focused {
    border-color: $black;
  }

  &.is-disabled {
    &,
    > .Select-control {
      background-color: $grey2;
      border-color: $grey2;
    }

    .Select-arrow {
      border-color: $grey2;
    }
  }

  &.error {
    border-color: $error-red;
  }
}

html .Select.is-disabled.has-value.Select--single {
  > .Select-control .Select-value .Select-value-label {
    color: $grey3;
  }
}

html .Select-control {
  border: 0;
  height: 32px;
  text-align: left;

  &:hover {
    box-shadow: none;
  }
}

html .Select--single {
  > .Select-control {
    .Select-value {
      padding: 0 12px;
    }
  }

  @include breakpoint(tablet) {
    > .Select-control {
      .Select-value,
      .Select-input {
        font-size: DEPRECATED_rem-desktop(16px);
      }
    }
  }
}

html .Select-input {
  padding-left: 12px;
  padding-top: 0;
}

html .Select-input > input {
  border: 0;
  margin-top: -2px;
}

html .Select-placeholder {
  @include placeholder-text;

  outline: none;
  padding: 0 15px;
  top: 2px;
  line-height: 32px; // vertical align hack since it's not an actual placeholder
}

html .Select-arrow-zone {
  padding: 0 15px 0 0;
  vertical-align: top;

  &:hover {
    .Select-arrow {
      border-top: none;
    }
  }
}

html .Select-menu-outer {
  margin-left: -5px;
  z-index: zindex(select);

  .Select-menu {
    .Select-option {
      &.is-selected,
      &.is-focused {
        color: $select-value-color;
      }
    }
  }
}

html .Select__autocomplete-container {
  position: relative;
  width: 100%;
}

html .Select-menu--autocomplete {
  background-color: $white;
  border-top: 0;
  position: absolute;
  top: 47px;
  width: 100%;
  z-index: 1;
}

html .Select-option {
  color: $select-value-color;

  &.is-selected,
  &.is-focused {
    background-color: $grey2;
  }
}

html .Select-arrow,
html .Select.is-open > .Select-control .Select-arrow {
  border: solid 1px $select-value-color;
  border-right: none;
  border-top: none;
  height: 8px;
  top: 0;
  transform: rotate(-45deg);
  width: 8px;
}

html .Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
}

html .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label,
html .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: $select-value-color;
}

.Field--select .Select.has-value.is-disabled {
  background-color: $grey2;
  background-image: url('/images/icons_v2/lock-outlined.svg');
  background-position: right 16px center;
  background-repeat: no-repeat;

  .Select-control {
    background-color: transparent;
  }
}
