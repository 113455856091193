.media-with-text-entry__image-element {
  display: block;
  width: 100%;
}

.image-with-text-entry__image-link--end {
  @include breakpoint(tablet) {
    order: 1;
  }
}

.media-with-text-entry__content--constrained-without-padding {
  @include breakpoint(tablet) {
    .image-with-text-entry__image-link--end + &,
    .rtl-direction & {
      padding: 0 0 0 24px;
    }

    .rtl-direction .image-with-text-entry__image-link--end + & {
      padding: 0 24px 0 0;
    }
  }
}
