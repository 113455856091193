.membership-banner {
  position: sticky;
  top: $header-nav-xs-height;
  padding: 16px 24px;
  background-color: $grey2;
  color: $grey5;
  z-index: 2;

  @include breakpoint(tablet) {
    top: $header-nav-sm-height;
  }
}

.membership-banner__content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.membership-banner__copy {
  @include breakpoint(tablet) {
    display: flex;
    align-items: center;
    margin: 0 $spacing-md 0 0;
  }
}

.membership-banner__copy__title {
  margin: 0 1em 0 0;
}

.membership-banner__copy__text {
  margin: 0 $spacing-xs 0 0;
}

// Only when these elements are side by side do we want to add spacing between
// them.
.membership-banner__copy__title + .membership-banner__copy__text {
  @include breakpoint(mobile) {
    margin-top: 0.5em;
  }
}
