.toggle-input {
  margin-bottom: 8px;

  .radio-button-group--inline & {
    @supports (display: grid) {
      margin: 0;
    }
  }
}

.toggle-input__input {
  appearance: none;
  opacity: 0;
}

.toggle-input__label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 16px 20px;
  border: 1px solid $grey2;
  border-radius: 2px;
  text-transform: none;
  letter-spacing: 0;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: $grey3;
  }

  :checked + & {
    border-color: $black;
  }

  :disabled + & {
    border-color: $grey2;
    background-color: $grey2;
    color: $grey4;
    cursor: default;
  }
}

.toggle-input__label--simple {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 18px;
  padding-bottom: 19px;

  :focus + & {
    @include focus-visible-outline();
  }

  :focus:not([data-focus-visible-added]) + & {
    outline: none;
  }
}

.toggle-input__label--with-subtext {
  padding-top: 13px;
  padding-bottom: 10px;
}

.toggle-input__label__badge {
  position: absolute;
  right: 20px;
  top: 16px;
}

.toggle-input__label--error {
  &,
  &:hover {
    border-color: $error-red;
  }
}

.toggle-input__label__icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  margin-top: 4px;
  border: 1px solid $grey3;
}

.toggle-input__label__icon--radio {
  border-radius: 50%;

  :checked + .toggle-input__label & {
    border: 4px solid $black;
  }

  :checked:disabled + .toggle-input__label & {
    border-color: $grey4;
  }

  :focus + .toggle-input__label & {
    @include focus-visible-outline();
  }
}

.toggle-input__label__icon--checkbox {
  background-image: url('//images/icons/white-checkmark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 0; // hide the checkmark until the input is :checked
  border-radius: 1px;

  :checked + .toggle-input__label & {
    background-color: $black;
    background-size: contain;
    border-color: $black;
  }

  :checked:disabled + .toggle-input__label & {
    background-color: $grey4;
    border-color: $grey4;
  }

  :focus + .toggle-input__label & {
    @include focus-visible-outline();
  }
}

.toggle-input__label__text {
  :not(.toggle-input__label--simple) > & {
    margin-left: 14px;
  }
}

.toggle-input__label__sub-text {
  display: block;
  margin-top: 2px;
}

.toggle-input__label__sub-text--simple {
  margin-top: 5px;
}

.toggle-input__error {
  color: $error-red;
  font-size: 14px;
}

.radio-button-group--inline {
  display: grid;
  grid-auto-flow: column;
  grid-gap: $spacing-xs;
}
