.product-listing {
  margin-top: 56px;
  margin-bottom: 64px;

  .rtl-direction & {
    direction: rtl;
  }
}

.product-listing__header {
  margin-bottom: 32px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 64px;
  }
}

.product-listing__header__heading,
.product-listing__header__lede,
.product-listing__header__description {
  margin: 0;
}

.product-listing__header__heading + .product-listing__header__lede {
  margin-top: 24px;
}

.product-listing__header__lede + .product-listing__header__description {
  margin-top: 24px;
}

.product-listing__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px 16px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 64px 24px;
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 64px 32px;
  }

  @include isIE11() {
    @include clear-floated-children;
  }
}

// Support for IE 11 because it doesn't support CSS Grid
.product-listing__items > * {
  float: left;
  width: calc(25% - 24px);
  margin: 0 24px 64px 0;

  @supports (display: grid) {
    width: auto;
    margin: 0;
  }
}

.product-listing .shop-button {
  justify-content: space-between;

  @include breakpoint(desktop) {
    justify-content: initial;
  }
}
