// ---------------------------------------------------------
// ModalBody Component
// Used with ModalContainer ModalHeader ModalFooter Components
// ---------------------------------------------------------

@import '../../../../core';

$spacing-modal-body-y-md: 298px;
$spacing-modal-body-x-md: 710px;

.business-modal-body {
  @include DEPRECATED_flexbox(column, center, center);
  @include DEPRECATED_flex(1);

  background: $white;
  min-height: $spacing-modal-body-y-md;
  min-width: 100%;
  padding: $spacing-xs;

  @include breakpoint(tablet) {
    min-width: $spacing-modal-body-x-md;
    padding: $spacing-md;
  }
}
