@import '../core';

.alert-banner {
  background-color: $grey1;
  color: $grey5;
  padding: 12px 5px;
  position: relative;
  text-align: center;
  z-index: 2;
}

.alert-banner a {
  text-decoration: none;
}

.alert-banner__content > p {
  display: inline;
  font-size: 14px;
}
