@import '../core';

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend,
label,
.label {
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  // TODO: remove these styles after we have new checkbox fields with borders
  &.invalid {
    border: 1px solid;
    padding: 1em;
  }

  &.invalid {
    border-color: $error-red;
  }
}

.checkbox-radio label,
.Field--checkbox label {
  margin-bottom: 0;
  margin-left: 8px;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
}

input {
  background-color: $white;
  border: 1px solid $grey2;
  border-radius: 2px;
  color: $grey5;
  font-family: $base-font-family;
  outline: none;
  padding: 10px 15px;

  &.invalid.invalid.invalid {
    border: 1px solid $error-red;
  }
}

input:focus {
  border: 1px solid $black;
}

input[type="tel"],
input[type="text"] {
  font-weight: 400;
  height: 48px;
  line-height: 1.33;

  &:hover {
    border-color: $grey3;
  }

  &:disabled,
  &[readonly] {
    background: $white;
    background-image: url('/images/icons/icon-locked.svg');
    background-position: right 0.5em center;
    background-repeat: no-repeat;
  }

  &::placeholder {
    @include placeholder-text;
  }
}

.invalid-feedback {
  color: $error-red;
  font-size: 12px;
}

.input__helper-text {
  display: block;
  margin-top: 8px;
  font-size: 14px;
}

.PasswordInput__wrapper {
  position: relative;
}

.PasswordInput__toggle {
  cursor: pointer;
  position: absolute;
  right: $form-field-horizontal-padding;
  top: $form-field-vertical-padding;
  text-align: center;
  width: auto;
  height: 12px;
}

.PasswordInput__icon--visible {
  fill: $DEPRECATED_dark-pastel-green;
}

.checkbox-group--indented {
  margin-left: 1em;
}
