$width-icon: 24px;
$padding-with-icon: $spacing-sm * 2 + $width-icon;

.text-input {
  margin: $spacing-sm 0;

  .rtl-direction & {
    direction: rtl;
  }
}

.text-input__field-wrapper {
  position: relative;
}

.text-input__label {
  position: absolute;
  top: 1px;
  left: $spacing-sm;
  right: $spacing-sm;
  margin: 0;
  padding: 18px 0 0;
  color: $grey3;
  cursor: text;
  transition: all 0.03s ease-out;
  overflow: hidden;

  .text-input--disabled & {
    cursor: not-allowed;
    background-color: $grey2;
  }

  .text-input--with-icon & {
    right: $padding-with-icon;

    .rtl-direction & {
      right: $spacing-sm;
      left: $padding-with-icon;
    }
  }
}

.text-input__label--float {
  padding-top: 10px;
  background-color: $white;
}

.text-input__input {
  width: 100%;
  padding: 18px 16px 8px;
  border: 1px solid $grey2;
  background-color: $white;
  color: $grey5;
  appearance: none; // Removes the inner shadow on mobile devices
  opacity: 1; // Fix for Safari on disabled inputs

  &:not([readonly]):not(:disabled):hover {
    border-color: $grey3;
  }

  &:disabled {
    border-color: $grey2;
    background-color: $grey2;
    color: $grey3;
    cursor: not-allowed;
  }

  .text-input--with-icon & {
    padding-right: $padding-with-icon;

    .rtl-direction & {
      padding-right: $spacing-sm;
      padding-left: $padding-with-icon;
    }
  }
}

.text-input__input--error {
  :hover > &,
  &:focus {
    border-color: $error-red;
  }
}

.text-input__helper-text,
.text-input__error {
  margin-top: 5px;
}

.text-input__icon {
  position: absolute;
  right: $spacing-sm;
  top: $spacing-sm;
  height: $width-icon;

  &--button {
    cursor: pointer;
  }

  .rtl-direction & {
    left: $width-icon;
    right: initial;
  }
}
