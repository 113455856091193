// ---------------------------------------------------------
// ModalContainer Component
// This is a substitute for Modal Component
// Used with ModalHeader ModalBody ModalFooter Components
// ---------------------------------------------------------

@import '../../../../core';

.business-modal {
  @include DEPRECATED_flexbox(column, flex-start, normal);

  background-color: $white;
  outline: none;
}

.business-modal__overlay {
  @include DEPRECATED_flexbox(row, center, center);

  background: rgba($black, 0.7);
  inset: 0;
  height: 100%;
  position: fixed;
  width: 100vw;
  z-index: 5000;
}

.business-modal__container {
  @include DEPRECATED_flexbox(column, flex-start, flex-start);

  border-radius: 4px;
  width: 100%;
}
