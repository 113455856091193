@import '../core';

// TODO: merge styles with .flash-messages
.FlashMessages {
  left: 0;
  position: fixed;
  right: 0;
  top: $header-nav-xs-height - 1px;
  z-index: 100;

  @include breakpoint(tablet) {
    top: $header-nav-sm-height - 1px;
  }
}

.FlashMessage {
  background-color: $black;
  color: $white;
  font-size: DEPRECATED_rem(16px);
  padding: 20px;
  text-align: center;

  @include breakpoint(tablet) {
    font-size: DEPRECATED_rem-desktop(16px);
  }
}

html .FlashMessage--success {
  background-color: $DEPRECATED_green-flash;
}

html .FlashMessage--error {
  background-color: $error-red;
}

.body__content.no-padding .FlashMessages {
  position: absolute;
  top: 0;
}

.flash-messages {
  a.compliance-error-link {
    cursor: pointer;
    text-decoration: underline;
  }
}
