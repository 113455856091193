@import '../core';

.footer {
  background-color: $black;
  color: $white;
  z-index: 2;
}

.footer--bottom-nav-air {
  padding-bottom: 64px;
}

.footer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(tablet) {
    flex-direction: row;
  }

  .rtl-direction & {
    direction: rtl;
  }
}

.footer__left,
.footer__right {
  flex-basis: 50%;
  margin-top: 16px;
  margin-bottom: 24px;
}

.footer__left {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  & a ~ a {
    margin-top: 8px;
  }
}

.footer__right {
  p {
    margin-top: 0;
  }

  a {
    font-weight: bold;
  }

  img {
    height: 46px;
    width: 46px;
    margin-right: 8px;

    .rtl-direction & {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.footer__left,
.footer__right {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
}

.footer-definition {
  flex: 1 1 40%;
  margin-right: 16px;

  @include breakpoint(desktop) {
    flex: 1 1 25%;
  }

  .rtl-direction & {
    margin-right: 0;
    margin-left: 16px;
  }
}

.footer-definition a {
  text-decoration: none;
}
