@import '../../core';

$steel: #1a1a1c;

@mixin font-overline-small {
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

$shadow-6: (
  0 0 32px rgba(0, 0, 0, 0.04),
  0 0 20px rgba(0, 0, 0, 0.1),
  0 0 5px rgba(0, 0, 0, 0.08),
);

.BottomNavigation {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  z-index: 1019;
  box-shadow: $shadow-6;
  height: 64px;

  @include breakpoint(tablet) {
    display: none;
  }

  svg.navIcon path {
    fill: $steel;
  }

  .item {
    text-align: center;
    padding: 6px 4px 24px;
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
    border-top: 2px solid #fff;
    position: relative;

    label {
      @include font-overline-small;

      display: inline-block;
      margin: 0;
      color: rgba($steel, 0.5);
      position: absolute;
      top: 32px;
    }

    .navIcon {
      opacity: 0.5;
    }

    &[data-active] {
      border-color: $steel;

      label {
        color: $steel;
      }

      .navIcon {
        opacity: 1;
      }
    }

    &[data-active][data-green] {
      border-color: $autoship-green;

      label {
        color: $autoship-green;
      }

      // paint img with auto-ship green
      // little hacky, but it works with <img> vectors
      img {
        filter: brightness(0) saturate(100%) invert(54%) sepia(76%) saturate(5432%) hue-rotate(147deg) brightness(92%) contrast(101%);
      }
    }
  }
}

.BottomNavigation--hidden {
  display: none;
}
