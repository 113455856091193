.build-your-plan-review__wrapper {
  max-width: 814px;
  margin-left: auto;
  margin-right: auto;
}

.build-your-plan-review__title {
  margin: 64px 0 32px;

  @include breakpoint(mobile) {
    display: none;
  }
}

.build-your-plan-review {
  @include breakpoint(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    align-items: start;
  }

  @include isIE11() {
    @include clear-floated-children;
  }
}

.build-your-plan-review__ledger {
  @include breakpoint(tablet) {
    padding: 24px;
    border: 1px solid $grey2;
  }

  @include isIE11() {
    width: calc(50% - 16px);
    float: left;
  }
}

.build-your-plan-review__edit-items {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  appearance: none;
  white-space: nowrap;
  flex-shrink: 0;
  background: none;
  cursor: pointer;
}

.build-your-plan-review__edit-items-text {
  text-decoration: underline;
}

.build-your-plan-review__item {
  display: flex;
  align-items: flex-start;

  &:last-child {
    @include breakpoint(tablet) {
      border-bottom: 0;
    }
  }
}

.build-your-plan-review__ledger {
  margin-top: 32px;

  @include breakpoint(tablet) {
    margin-top: 0;
  }

  @include isIE11() {
    margin-left: 32px;
  }
}

.build-your-plan-review__ledger .ledger {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}
