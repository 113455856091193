.primary-notification {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $grey1;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  transform-origin: center;

  & + & {
    margin-top: 8px;
  }

  .rtl-direction & {
    direction: rtl;
  }
}

.primary-notification--exit {
  opacity: 1;
}

.primary-notification--exit-active {
  opacity: 0;
  transform: scale(0.9);
}

.primary-notification--cart {
  background-color: $grey5;
  color: $white;
}

.primary-notification--warning {
  background-color: $error-red;
  color: $white;
}

.primary-notification--auto-ship {
  background-color: $autoship-green;
  color: $white;
}

.primary-notification__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
}

.primary-notification__content.content-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.primary-notification__content--dismissible {
  justify-content: flex-start;
  padding-right: $spacing-md;
  text-align: inherit;
}

.primary-notification__message {
  .rtl-direction & {
    margin-right: 0;
  }
}

.primary-notification__link {
  margin-top: 6px;
  text-decoration: underline;
  color: inherit;
  flex-basis: 100%;
}

.primary-notification__dismiss {
  @include fluid-type(8px, 80px, $properties: 'right');

  position: absolute;
  top: 0;
  padding: 8px;
  background: none;
  border: 0;
  appearance: none;
  font-size: 0;

  .primary-notification--auto-ship &,
  .primary-notification--cart &,
  .primary-notification--warning & {
    filter: brightness(0) invert(1); // turns our dark icon white
  }

  .rtl-direction & {
    @include fluid-type(8px, 80px, $properties: 'left');

    right: auto;
  }
}
