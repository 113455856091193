@import '../core';

html {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

body {
  // Ideally we would avoid @extend, but this is the simplest way to keep our
  // default body styles the same as text--body.
  @extend .text--body;

  color: $grey5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;

  &:hover {
    text-decoration: underline;
  }

  &.disabled {
    pointer-events: none;
  }
}

ul,
ol {
  // Remove the normal indent that lists have
  padding-left: 1em;

  body.rtl-direction & {
    direction: rtl;
    padding-left: 0;
    padding-right: 1em;
  }
}

p {
  margin-bottom: 1em;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.3;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.left {
  text-align: left;
}

.rtl-direction {
  .left {
    text-align: right;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.center {
  text-align: center;
}

.no-decoration {
  text-decoration: none;
}

// This is added in Bootstrap 4.1, but we're on 4.0. Remove after updating.
.text-wrap {
  word-wrap: break-word;
}

.autoship-text {
  color: $autoship-green;
}

.heading--large,
h1 {
  @include fluid-type(32px, 40px);

  color: $black;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.3;
}

.heading--medium,
h2 {
  @include fluid-type(22px, 24px);

  color: $black;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.3;
}

.heading--small,
h3 {
  @include fluid-type(16px, 18px);

  color: $black;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.3;
}

.heading--mini {
  color: $black;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.27;
}

.heading--green {
  color: $autoship-green;
}

.subheading {
  color: $black;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.09em;
  line-height: 1.43;
  text-transform: uppercase;
}

.subheading--small {
  color: $black;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.45;
  text-transform: uppercase;
}

.subheading--mini {
  color: $black;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.45;
  text-transform: uppercase;
}

.text--body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
}

.text--body--small {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  text-transform: none;
}

.text--button {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.45;
  text-transform: uppercase;
}

.text--tag {
  color: $black;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.5;
  text-transform: uppercase;
}

// To avoid a browser-crashing bug in Safari 12 and earlier we cannot use our
// Dogpatch font on select elements. This isn't something that the user will end
// up seeing because of how we use our SelectInput but we want to provide a
// decent fallback here just in case.
select {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
}
