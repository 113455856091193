@import '../core.scss';
@import './snackbar-animations';

.snackbar-container {
  position: relative;
}

.snackbar-item {
  padding: 17px 24px 20px;
  border: 1px solid $grey2;
  box-shadow: 2px 2px 5px 0 rgba(10, 10, 10, 0.18);
  background-color: $white;
}

.snackbar-item--top {
  margin-top: 16px;

  @include animation-top-slide-down();
}

.snackbar-item--bottom {
  margin-bottom: 16px;

  @include animation-bottom-slide-up();
}

.snackbar-item--added-to-cart {
  margin: 16px;

  @include animation-bottom-slide-up();

  @include breakpoint(tablet) {
    margin: 16px 0 0;

    @include animation-top-slide-down();
  }
}

.snackbar-item--shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.snackbar-drawer {
  z-index: -1; // So the snackbar can slide out from under the header
  display: flex;
  flex-direction: column;

  &--position-top {
    position: relative;
  }

  &--position-bottom {
    position: fixed;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column-reverse;
  }

  &--position-added-to-cart {
    @include breakpoint(mobile) {
      position: fixed;
      bottom: 64px;
      left: 50%;
      transform: translateX(-50%);
    }

    @include breakpoint(tablet) {
      position: absolute;
      top: 0;
      right: 0;

      .rtl-direction & {
        right: initial;
        left: 0;
      }
    }
  }

  @include breakpoint(mobile) {
    width: 100%;
  }
}
