$steel: #1a1a1c;

.Level1InfoModal {
  justify-content: center;

  .Modal__content {
    max-width: 343px;
    height: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.08), 0 0 20px rgba(0, 0, 0, 0.1), 0 0 32px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    @include breakpoint(tablet) {
      max-width: 400px;
    }
  }

  .Modal__body {
    padding: 0;

    &::after {
      height: 12px;
    }
  }

  .info-card {
    border: 0;
    padding: 22px $spacing-sm 0;

    &__heading {
      margin-bottom: 12px;
    }
  }

  hr {
    background-color: $grey1;
    border: 0;
    height: 1px;
    margin: 12px 0;
  }

  &__button-container {
    padding: 0 $spacing-sm;

    .button {
      width: 100%;
      border-radius: $spacing-xxs;
      background: $steel;
    }
  }

  .Modal__closer-circle {
    transform: none;
    right: 0;
    left: auto;
  }

  .Modal__close-svg path {
    fill: $steel;
  }
}
