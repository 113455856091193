.text-input-button-container {
  display: flex;

  .text-input {
    width: 65%;
    margin: 0;
  }

  .button {
    padding: 16px;
    width: 35%;
    height: 52px;

    &:hover:not(:disabled):not(.button--no-icon),
    &:focus:not(:disabled):not(.button--no-icon) {
      padding-left: 9px;
      padding-right: 23px;
    }
  }
}
