@import '../core';

.scalable-content-section {
  margin-top: $spacing-xxl;
  margin-bottom: $spacing-xxl;

  @include breakpoint(desktop) {
    margin-top: $spacing-xxxl;
    margin-bottom: $spacing-xxxl;
  }
}

.scalable-content-section:empty {
  margin-top: 0;
  margin-bottom: 0;
}

.scalable-content-section__links {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-md;

  & > :nth-child(n+2) {
    margin-top: $spacing-sm;
  }

  button {
    width: 100%;
  }

  @include breakpoint(tablet) {
    flex-direction: row;

    & > :nth-child(n+2) {
      margin-top: 0;
      margin-left: $spacing-sm;
    }

    button {
      width: auto;
    }
  }
}

.scalable-content-section--border-bottom {
  padding-bottom: $spacing-xxl;
  border-bottom: 1px solid #e9e9e9;

  @include breakpoint(desktop) {
    padding-bottom: $spacing-xxxl;
  }
}

.scalable-content-section--top-padding-none {
  margin-top: 0;
}

.scalable-content-section--top-padding-small {
  margin-top: $spacing-md;

  @include breakpoint(tablet) {
    margin-top: 40px;
  }
}

.scalable-content-section--top-padding-large {
  margin-top: $spacing-xxxxl;

  @include breakpoint(tablet) {
    margin-top: 120px;
  }
}

.scalable-content-section--bottom-padding-none {
  margin-bottom: 0;
}

.scalable-content-section--bottom-padding-small {
  margin-bottom: $spacing-md;

  @include breakpoint(tablet) {
    margin-bottom: 40px;
  }

  &.scalable-content-section--border-bottom {
    padding-bottom: $spacing-md;

    @include breakpoint(tablet) {
      padding-bottom: 40px;
    }
  }
}

.scalable-content-section--bottom-padding-large {
  margin-bottom: $spacing-xxxxl;

  @include breakpoint(tablet) {
    margin-bottom: 120px;
  }

  &.scalable-content-section--border-bottom {
    padding-bottom: $spacing-xxxxl;

    @include breakpoint(tablet) {
      padding-bottom: 120px;
    }
  }
}
