@import '../core';

@mixin spinner($color: $white, $background-color: rgba($white, 0.2)) {
  animation: spinner 1s infinite linear;
  border: 2px solid $background-color;
  border-left-color: $color;
  border-radius: 50%;
}

.Spinner {
  @include spinner($white);
}

.Spinner--gray {
  @include spinner($grey3, $grey1);
}

.Spinner--small {
  height: 16px;
  width: 16px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
