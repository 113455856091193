.product-image__container {
  position: relative;
  background-color: $grey1;
}

.product-image__image {
  width: 100%;
  display: block;
}

.product-image__star-rating-container {
  position: absolute;
  top: $spacing-xs;
  left: $spacing-xs;

  .rtl-direction & {
    left: initial;
    right: $spacing-xs;
  }
}

.product-image__detail-container {
  position: absolute;
  top: $spacing-xs;
  right: $spacing-xs;

  .rtl-direction & {
    right: initial;
    left: $spacing-xs;
  }
}
