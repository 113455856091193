@import 'colors';

// TODO: these colors are deprecated and should no longer be used in the
// this application. See core/_colors.scss for a list of current colors.
$DEPRECATED_dark-pastel-green: #53ac5a;
$DEPRECATED_dusty-gray: #949494;
$DEPRECATED_light-blue: #73b2d5;
$DEPRECATED_flat-blue: #3f7bab;
$DEPRECATED_green-flash: #53ac5a;
$DEPRECATED_light-blue-grey: #dae7f0;
$DEPRECATED_pale-blue: #f3f7fa;
$DEPRECATED_pale-grey: #eff5f9;

// Responsive breakpoints.
$screen-xs-min: 320px; //mobile
$screen-sm-min: 768px; // Tablet
$screen-md-min: 1024px; // Desktop small
$screen-lg-min: 1200px; // Desktop large
$screen-xlg-min: 1400px; // Desktop extra large
$screen-xxlg-min: 2560px; // 4K

// Fonts
$dogpatch: 'Dogpatch';

// Body variables
$base-background: #fff !default;
$base-font-family: $dogpatch, sans-serif !default;
$base-font-family-serif: Times, 'Times-Roman', serif !default;
$base-font-size: 16px !default;
$base-line-height: 1.33 !default;

// Link variables.
$base-link-hover-color: $DEPRECATED_light-blue !default;
$base-link-hover-text-decoration: none !default;

// Header nav.
$header-nav-xs-height: 62px;
$header-logo-nav-sm-height: 65px;
$header-nav-sm-height: 120px;
$header-nav-wayfinder-border-bottom: 4px solid $grey5;
$box-shadow-color: rgba(101, 101, 101, 0.15);

// Layout
$layout-max-width: 1440px;

// Flash.
$flash-height: 48px;

// Account navigation.
$account-nav-sm-max-width: 240px;

// Page padding
$base-page-padding: 30px;
$base-desktop-page-padding: 64px;

// Display types
$displays: block, flex, inline, inline-block, inline-flex;

// Controls.
$select-height: 46px;
$select-value-color: $grey5;

// Z-Index
$z-index-map: (
  loading-splash: 1300,
  sidenav: 1200,
  cart-mobile: 1020,
  nicotine-warning: 1001,
  header: 1000,
  cart: 980,
  navigation: 950,
  navigation-overlay: 949,
  tooltip: 940,
  popover: 930,
  select: 925,
  modal: 920,
);

// Spacing Units
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 48px;
$spacing-xxl: 64px;
$spacing-xxxl: 80px;
$spacing-xxxxl: 96px;

// Fixes an issue with our font line heights at smaller sizes. The font will take
// up slightly more space at the bottom than is actually displayed, so we need to
// offset this with a top margin so that it can be correctly centered vertically.
$font-vertical-align-offset: 3px;

// Forms
$form-field-vertical-padding: 9px;
$form-field-horizontal-padding: 16px;
