.hero {
  color: $grey5;
  background-color: $grey1;

  @include breakpoint(tablet) {
    padding-top: 64px;
    padding-bottom: 32px;
    background-color: transparent;
  }
}

.hero__content,
.hero__content--devices {
  @include DEPRECATED_flexbox(column, space-between, initial);

  @include breakpoint(tablet) {
    display: block;
    padding: 0;
  }
}

.hero__content--devices {
  padding-top: 30px;

  @include DEPRECATED_flexbox(row, space-between, flex-end);

  @include breakpoint(tablet) {
    padding: 0;
  }
}

.hero__text--devices {
  margin-bottom: 30px;

  @include breakpoint(tablet) {
    margin-bottom: 0;
  }
}

.hero__title-pods {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 0;
}

.hero__text {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;

  @include breakpoint(tablet) {
    padding: 0;
  }
}

.hero__image--juul-device {
  height: 125px;
  margin: 0 10px 0 5px;
}

.hero__single-image {
  display: block;
  width: 100%;
}

.variant-image {
  position: relative;
}

.variant-image__star-rating {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;

  .rtl-direction & {
    left: auto;
    right: 8px;
  }
}

.hero__price {
  @include DEPRECATED_flexbox(row, space-between, center);

  flex-wrap: wrap;

  @include breakpoint(tablet) {
    display: block;
  }
}

.hero__price-container {
  padding-top: 5px;
}
