// ---------------------------------------------------------
// ModalFooter Component
// Used with ModalContainer ModalBody ModalHeader Components
// ---------------------------------------------------------

@import '../../../../core';

$spacing-modal-footer-y-md: 116px;
$spacing-modal-footer-x-md: 710px;

.business-modal-footer {
  @include DEPRECATED_flexbox(row, center, center);
  @include DEPRECATED_flex(1);

  background: $white;
  min-height: $spacing-modal-footer-y-md;
  min-width: 100%;
  padding: $spacing-xs;
  flex-wrap: wrap;

  @include breakpoint(tablet) {
    min-width: $spacing-modal-footer-x-md;
    padding: $spacing-md;
    flex-wrap: none;
  }
}

$spacing-modal-footer-button-y-md: 44px;
$spacing-modal-footer-button-x-md: 224px;
$spacing-modal-footer-button-gutter: 4px;

.business-modal-footer__button--confirm,
.business-modal-footer__button--cancel {
  margin: $spacing-modal-footer-button-gutter;
  min-height: $spacing-modal-footer-button-y-md;
  min-width: 100%;

  @include breakpoint(tablet) {
    min-width: $spacing-modal-footer-button-x-md;
  }
}
