@import "../core";

.delivery-info {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  padding: inherit;
}

.delivery-info-placeholder {
  background-color: #e9e9e9;
  height: 12px;
  width: 65px;

  @include breakpoint(mobile) {
    display: none;
  }
}

.delivery-info-text {
  @include breakpoint(mobile) {
    display: none;
  }

  .desktop-navigation__container & {
    display: none;

    @include breakpoint(desktop) {
      display: block;
    }
  }
}

.delivery-info-icon {
  width: 26px;
  position: relative;

  @include breakpoint(tablet) {
    width: auto;
  }

  .icon {
    display: block;

    .desktop-navigation__container & {
      margin-right: 8px;
    }
  }

  &__inactive {
    &::before {
      content: '!';
      font-size: 10px;
      color: white;
      line-height: 14px;
      text-align: center;
      position: absolute;
      right: 8px;
      top: 4px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $error-red;
    }

    .mobile-navigation__container & {
      &::before {
        top: 18px;
        right: -2px;
      }
    }
  }
}
