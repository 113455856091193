@import '../core';

$width-transition-distance: 7px;
$transition-timing-button: cubic-bezier(0.19, 1, 0.22, 1);

$padding-button-vertical: 16px;
$padding-button-horizontal: 50px;
$padding-button: $padding-button-vertical $padding-button-horizontal;

$padding-button-horizontal-full-width: 16px;
$padding-button-full-width: $padding-button-vertical $padding-button-horizontal-full-width;

// Default button colors
$color-button-background: $black;
$color-button-border: $color-button-background;
$color-button-text: $white;
$color-button-disabled-background: $grey2;
$color-button-disabled-border: $color-button-disabled-background;
$color-button-disabled-text: $grey4;

// Inverted default button colors
$color-button-background-inverted: $white;
$color-button-border-inverted: $color-button-background-inverted;
$color-button-text-inverted: $black;
$color-button-disabled-background-inverted: $DEPRECATED_dusty-gray;
$color-button-disabled-border-inverted: $color-button-disabled-background-inverted;
$color-button-disabled-text-inverted: $grey2;

// Vivid button colors
$color-button-vivid-background: $autoship-green;
$color-button-vivid-border: $color-button-vivid-background;
$color-button-vivid-text: $white;

// Outlined button colors
$color-button-outline-background: transparent;
$color-button-outline-border: $black;
$color-button-outline-text: $black;

// Inverted outline button colors
$color-button-outline-background-inverted: transparent;
$color-button-outline-border-inverted: $white;
$color-button-outline-text-inverted: $white;

// Soft Outlined button colors
$color-button-outline-soft-background: transparent;
$color-button-outline-soft-border: $grey2;
$color-button-outline-soft-text: $black;

// Soft Outlined outline button colors
$color-button-outline-soft-background-inverted: transparent;
$color-button-outline-soft-border-inverted: $grey4;
$color-button-outline-soft-text-inverted: $white;

// Danger button colors
$color-button-danger-background: $error-red;
$color-button-danger-border: $error-red;
$color-button-danger-text: $white;

// Tertiary dark button colors
$color-button-tertiary-text: $black;

// Tertiary light button colors
$color-button-tertiary-text-inverted: $white;

button {
  font-family: $base-font-family;
}

// Do not manually apply this class unless under very specific circumstances. If
// you are adding a button to a Rails view use the `button_component` view helper.
// If you are adding a button to a React view use the `Button`
// component. Both of these helpers will provide you with the necessary SVG
// icon for hover states to work.
.button {
  display: inline-block;
  padding: $padding-button;
  border: 1px solid $color-button-border;
  border-radius: 0;
  background-color: $color-button-background;
  color: $color-button-text;
  font-family: $base-font-family;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  cursor: pointer;
  transition: padding 0.8s $transition-timing-button;

  // We need to transition our button padding in order to display the button's
  // icon.
  &:hover:not(:disabled):not(.button--no-icon),
  &:focus:not(:disabled):not(.button--no-icon) {
    text-decoration: none;
    padding-left: $padding-button-horizontal - $width-transition-distance;
    padding-right: $padding-button-horizontal + $width-transition-distance;

    body.rtl-direction & {
      padding-left: $padding-button-horizontal + $width-transition-distance;
      padding-right: $padding-button-horizontal - $width-transition-distance;
    }
  }

  // Extra specificity to make sure that we override any additional classes on
  // the element
  &:disabled:disabled,
  &.disabled {
    border-color: $color-button-disabled-border;
    background-color: $color-button-disabled-background;
    color: $color-button-disabled-text;
    cursor: not-allowed;
  }
}

.button__text {
  position: relative;
  display: inline-block;
}

.button__icon {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(5px, -50%);
  opacity: 0;
  transition: opacity 0.5s $transition-timing-button;

  .button--no-icon & {
    display: none;
  }

  body.rtl-direction & {
    left: auto;
    right: 100%;
    transform: translate(-5px, -50%) scale(-1);
  }

  .button:not(.button--tertiary):hover:not(:disabled) &,
  .button:focus:not(:disabled) & {
    opacity: 1;
  }

  .icon-stroke {
    stroke: currentColor;
  }

  .icon-fill {
    fill: currentColor;
  }
}

// Additional Button Styles
// Vivid, Outline, Outline-Soft

.button--vivid {
  &,
  &.button--inverted {
    background-color: $color-button-vivid-background;
    border-color: $color-button-vivid-border;
    color: $color-button-vivid-text;
  }
}

.button--outline {
  background-color: $color-button-outline-background;
  border-color: $color-button-outline-border;
  color: $color-button-outline-text;

  &.button--inverted {
    background-color: $color-button-outline-background-inverted;
    border-color: $color-button-outline-border-inverted;
    color: $color-button-outline-text-inverted;
  }
}

.button--outline-soft {
  background-color: $color-button-outline-soft-background;
  border-color: $color-button-outline-soft-border;
  color: $color-button-outline-soft-text;

  &.button--inverted {
    background-color: $color-button-outline-soft-background-inverted;
    border-color: $color-button-outline-soft-border-inverted;
    color: $color-button-outline-soft-text-inverted;
  }
}

// Button Modifier Classes
// Inverted, Full-width, Small, No-icon

.button--inverted {
  background-color: $color-button-background-inverted;
  border-color: $color-button-border-inverted;
  color: $color-button-text-inverted;

  &:disabled:disabled {
    border-color: $color-button-disabled-border-inverted;
    background-color: $color-button-disabled-background-inverted;
    color: $color-button-disabled-text-inverted;
  }
}

.button--danger {
  background-color: $color-button-danger-background;
  border-color: $color-button-danger-border;
  color: $color-button-danger-text;
}

@mixin button-full-width {
  width: 100%;
  padding: $padding-button-full-width;

  &:hover:not(:disabled):not(.button--no-icon),
  &:focus:not(:disabled):not(.button--no-icon) {
    padding-left: $padding-button-horizontal-full-width - $width-transition-distance;
    padding-right: $padding-button-horizontal-full-width + $width-transition-distance;

    body.rtl-direction & {
      padding-left: $padding-button-horizontal-full-width + $width-transition-distance;
      padding-right: $padding-button-horizontal-full-width - $width-transition-distance;
    }
  }
}

.button--full-width,
.button-group > * {
  @include button-full-width;
}

.button--full-width-mobile {
  @include breakpoint(mobile) {
    @include button-full-width;
  }
}

.button--small {
  padding-top: $padding-button-vertical - 4px;
  padding-bottom: $padding-button-vertical - 4px;
  font-size: 11px;
}

// We must set the text-decoration on the child element to get around a bug with
// inline-block
.button--no-icon {
  .button__text::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid currentColor;
    transition: transform 0.3s $transition-timing-button;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      .button__text::after {
        transform: translateY(3px);
      }
    }
  }
}

.button--tertiary {
  @extend .button--no-icon;

  background-color: transparent;
  border-color: transparent;
  color: $color-button-tertiary-text;

  &.button--inverted {
    color: $color-button-tertiary-text-inverted;
  }
}

// Group buttons and display them side by side on desktop or stacked on mobile
.button-group {
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  grid-gap: 16px;

  @include breakpoint(tablet) {
    grid-auto-flow: column;
  }
}

.button__processing {
  display: flex;
  justify-content: center;
}

.button__processing__spinner {
  height: 1em;
  width: 1em;
}

.button__processing__text {
  margin-left: 20px;
}

.button--no-appearance {
  appearance: none;
  padding: 0;
  outline: none;
  border: none;
  background: none;
}
