@import '../core';

.Field__label--radio {
  font-size: 18px;
  line-height: 18px;
  border: 1px solid $grey2;
  padding: 1rem 3rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;

  // Show medium border on radio hover
  &:hover {
    border-color: $grey3;
  }
}

// Show dark border when radio is checked
.Field--radio input:checked + label {
  border-color: $grey5;
}

/* Hides the original radio button */
.Field--radio > input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

/* Create a custom radio button */
.Field__input--radio-button {
  position: absolute;
  top: 2rem;
  left: 1rem;
  height: 13px;
  width: 13px;
  border: 1px solid $grey3;
  background-color: $white;
  border-radius: 50%;
}

.Field--radio:hover .Field__input--radio-button {
  border: 1px solid $grey5;
}

.Field--radio input:checked + label .Field__input--radio-button {
  background-color: $grey5;
  border-color: $grey5;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Field__input--radio-button::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.Field--radio input:checked + label .Field__input--radio-button::after {
  display: block;
}

/* Style the indicator (dot/circle) */
.Field__input--radio-button::after {
  top: 3px;
  left: 3px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}
