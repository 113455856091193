.line-item {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-top: 1px solid $grey2;
  border-bottom: 1px solid $grey2;

  @include breakpoint(tablet) {
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &:first-child {
    border-top: none;
    padding-top: 0;
  }

  & + & {
    border-top: none;
  }

  .rtl-direction & {
    direction: rtl;
  }
}

.line-item__image,
.line-item__image-link {
  flex-shrink: 0;
}

.line-item__image {
  display: block;
  width: 60px;
  margin-right: 8px;

  .rtl-direction & {
    margin-left: 8px;
    margin-right: 0;
  }
}

.line-item__info {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.line-item__remove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0 5px 10px;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  .rtl-direction & {
    left: 0;
    right: auto;
    padding: 0 10px 5px 0;
  }
}

.line-item__name,
.line-item__meta {
  padding-right: 40px;

  .rtl-direction & {
    padding-left: 40px;
    padding-right: 0;
  }
}

.line-item__name {
  margin: 0;
}

.line-item__name-link {
  text-decoration: none;
}

.line-item__meta {
  margin-top: 2px;
  flex-grow: 1;
  color: $grey4;
}

.line-item__quantity-and-prices {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 8px;
}

.line-item__quantity-label {
  margin: 0 0 2px;
}

.line-item__prices {
  text-align: right;

  .rtl-direction & {
    text-align: left;
  }
}

.line-item__price,
.line-item__discount-price {
  display: block !important; // override RTL styles
}

.line-item__discount-price--strikethrough {
  text-decoration: line-through;
}

.line-item__price--color-autoship {
  color: $autoship-green;
}
