@import 'navigation/mobile';

.desktop-navigation__container {
  border-top: 0; // Reset for header__nav-wrapper
  box-shadow: none; // Reset for header__nav-wrapper

  @include breakpoint(mobile) {
    display: none;
  }
}

.desktop-navigation__primary-items-container,
.desktop-navigation__secondary-items-container {
  border-bottom: 1px solid $grey2;
}

.desktop-navigation__group,
.desktop-navigation__dropdown-content__link-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.desktop-navigation__primary-items,
.desktop-navigation__secondary-items {
  display: flex;
}

.desktop-navigation__group {
  display: inline-flex;
  align-items: center;
}

.desktop-navigation__group--position-end {
  justify-content: flex-end;
  flex-grow: 1;
}

.desktop-navigation__primary-item-dropdown {
  display: flex;
}

.desktop-navigation__primary-item,
.desktop-navigation__secondary-item {
  display: flex;
  align-items: center;
}

$hover-border-width: 4px;

.desktop-navigation__primary-item__content {
  position: relative;
  display: block;
  text-align: center;
  padding-top: 23px;
  padding-bottom: 24px;
  background-color: transparent;
  border: none;
  color: inherit;

  @include fluid-type(
    $spacing-xs,
    $spacing-sm,
    $vw-min: $screen-sm-min,
    $vw-max: $screen-xlg-min,
    $properties: ('padding-left', 'padding-right')
  );

  &::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    border-bottom: $hover-border-width solid transparent;
    content: '';
  }

  .desktop-navigation__primary-item:hover > &,
  .desktop-navigation__primary-item:focus > & {
    &::after {
      border-color: $grey5;
    }

    &.navigation__primary-item--color-auto-ship::after {
      border-color: $autoship-green;
    }
  }
}

.desktop-navigation__logo__icon {
  display: block;
  padding-top: 17px;
  padding-bottom: 17px;
}

.desktop-navigation__cart__icon {
  padding-top: 18px;
  padding-bottom: 18px;
  cursor: pointer;
}

.desktop-navigation__secondary-item__content {
  display: flex;
  align-items: center;
  text-align: center;

  @include fluid-type(
    $spacing-xs,
    $spacing-md / 2,
    $vw-min: $screen-sm-min,
    $vw-max: $screen-xlg-min,
    $properties: ('padding-left', 'padding-right')
  );
}

.desktop-navigation__group--position-start {
  [dir='ltr'] & {
    .desktop-navigation__primary-item:first-child .desktop-navigation__primary-item__content,
    .desktop-navigation__secondary-item:first-child .desktop-navigation__secondary-item__content {
      padding-left: 0;
    }
  }

  [dir='rtl'] & {
    .desktop-navigation__primary-item:first-child .desktop-navigation__primary-item__content,
    .desktop-navigation__secondary-item:first-child .desktop-navigation__secondary-item__content {
      padding-right: 0;
    }
  }
}

.desktop-navigation__group--position-end {
  [dir='ltr'] & {
    .desktop-navigation__primary-item:last-child .desktop-navigation__primary-item__content,
    .desktop-navigation__secondary-item:last-child .desktop-navigation__secondary-item__content {
      padding-right: 0;
    }
  }

  [dir='rtl'] & {
    .desktop-navigation__primary-item:last-child .desktop-navigation__primary-item__content,
    .desktop-navigation__secondary-item:last-child .desktop-navigation__secondary-item__content {
      padding-left: 0;
    }
  }
}

.desktop-navigation__secondary-item__icon {
  display: none;

  @include breakpoint(desktop) {
    display: inline-block;

    [dir='ltr'] & {
      margin-right: $spacing-xs;
    }

    [dir='rtl'] & {
      margin-left: $spacing-xs;
    }
  }
}

.desktop-navigation__secondary-item__text {
  padding-top: 14px;
  padding-bottom: 12px;
}

.navigation__primary-item--color-auto-ship {
  color: $autoship-green;
}

.desktop-navigation__locale-selector {
  padding-top: 12px;
  padding-bottom: 12px;
}

.desktop-navigation__dropdown-content {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $white;
  border-bottom: 1px solid $grey2;
  z-index: zindex(header);
}

.desktop-navigation__dropdown-content--open {
  visibility: visible;
}

.desktop-navigation__dropdown-columns {
  list-style-type: none;
  padding: 0;
}

.desktop-navigation__dropdown-content__link-item {
  & + & {
    margin-top: $spacing-sm;
  }
}

.desktop-navigation__promotional-image {
  width: 100%;
  display: block;
}

.desktop-navigation__promotional-text {
  margin-top: $spacing-xs;
  margin-bottom: 0;
}

.desktop-navigation__dropdown-content__text {
  display: block;
  margin: 0;
}

.account-link-icon {
  display: flex;

  @include breakpoint(desktop) {
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
  }

  @include fluid-type(
    $spacing-xs,
    $spacing-sm,
    $vw-min: $screen-sm-min,
    $vw-max: $screen-xlg-min,
    $properties: ('padding-left', 'padding-right')
  );
}
