@import '../core';

.LoadingSplash {
  @include DEPRECATED_flexbox(column, center, center);

  background-color: $white;
  height: calc(100vh - #{$header-nav-xs-height});
  width: 100%;

  @include breakpoint(tablet) {
    height: calc(100vh - #{$header-nav-sm-height});
  }
}

.LoadingSplash__spinner {
  height: 50px;
  width: 50px;
}

.LoadingSplash__text {
  @include DEPRECATED_font-sizes(18px);

  margin-top: 17px;
}
