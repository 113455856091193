.catalog-page {
  .catalog-page__support-return-policy {
    margin: 0 auto;
    max-width: 720px;
    padding: 32px 8px;
  }

  .catalog-page__support-buttons {
    @include DEPRECATED_flexbox(row, center, center);

    @include breakpoint(mobile) {
      @include DEPRECATED_flexbox(column, center, center);
    }
  }

  .catalog-page__support_links {
    padding: 32px 8px;
  }

  .catalog-page__support-buttons a {
    margin: 8px 16px;
    flex-basis: 25%;

    @include breakpoint(mobile) {
      flex-basis: 100%;
      width: 315px;
    }
  }
}
