.product-display-grid,
.product-display__container:not(.product-display__container--mobile-layout) {
  @include breakpoint(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include fluid-type(
      $spacing-sm,
      $spacing-lg,
      $vw-min: $screen-sm-min,
      $vw-max: $screen-xlg-min,
      $properties: ('grid-column-gap')
    );
  }
}

.rtl-direction .product-display-grid {
  direction: rtl;
}

.product-display__container {
  margin-top: 64px;
  margin-bottom: 64px;

  .rtl-direction & {
    direction: rtl;
  }

  @include clear-floated-children;
}

.product-display__container--image-start {
  grid-template-areas:
    'image heading'
    'image cta';
}

.product-display__container--image-end {
  grid-template-areas:
    'heading image'
    'cta image';
}

.product-display__image,
.product-display__lede,
.product-display__description,
.product-display__color-swatch-selector,
.product-display__cta-buttons {
  @include fluid-type(
    $spacing-sm,
    $spacing-md,
    $vw-min: $screen-sm-min,
    $vw-max: $screen-xlg-min,
    $properties: ('margin-top')
  );
}

.product-display__image {
  display: block;

  @include isIE11() {
    width: calc(50% - 1rem);

    .product-display__container--image-start &,
    .rtl-direction .product-display__container--image-end & {
      float: left;
    }

    .rtl-direction .product-display__container--image-start &,
    .product-display__container--image-end & {
      float: right;
    }
  }

  @include breakpoint(tablet) {
    .product-display__container:not(.product-display__container--mobile-layout) & {
      margin-top: 0;
      grid-area: image;
    }
  }
}

.product-display__heading-container,
.product-display__cta-container {
  @include isIE11() {
    float: right;
    width: calc(50% - 1rem);

    .rtl-direction &,
    .product-display__container--image-end & {
      float: left;
    }
  }
}

.product-display__heading-container {
  align-self: end;
  grid-area: heading;
}

.product-display__heading {
  margin: 0;
}

.product-display__lede {
  margin-bottom: 0;
}

.product-display__cta-container {
  grid-area: cta;
}

.product-display__color-swatch-selector {
  display: grid;
  grid-auto-columns: fit-content($spacing-xl);
  grid-gap: $spacing-sm;

  @include isIE11() {
    display: flex;

    & > label + label {
      margin-left: $spacing-sm;
    }

    .rtl-direction & > label + label {
      margin-right: $spacing-sm;
      margin-left: 0;
    }
  }
}

.product-display__cta-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * + * {
    margin-top: 8px;
  }
}

.product-display__learn-more {
  display: flex; // Left-aligns text while supporting RTL
  padding-left: 0;
  padding-right: 0;
}
