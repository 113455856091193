.juul2-promo-modal {
  .Modal__header {
    padding: 0;

    @media (max-width: 767px) and (min-aspect-ratio: 5/4), (max-height: 480px) {
      img {
        display: none;
      }
    }
  }

  .entry-content__call-to-action {
    @include breakpoint(tablet) {
      max-width: none;
    }
  }
}
