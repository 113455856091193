@import '../core';

.rtl-direction {
  .footer__social-icon {
    transform: scaleX(-1);
  }

  .Field--checkbox {
    text-align: right;

    .intl-text {
      width: 100%;
    }
  }

  .quickShop__content {
    text-align: right;
    direction: rtl;
  }

  .home-newsletter__input {
    margin-left: 36px;
    margin-right: 0;
  }

  .device-features__icon {
    transform: scaleX(-1);
  }

  .home-newsletter {
    direction: rtl;
  }

  .account-container {
    direction: rtl;

    .RegisterProductPage {
      .BackButton__icon {
        margin-left: 14px;
        margin-right: 0;
      }

      .RegisterProductForm__product-type,
      .RegisterProductForm__warranty-sku,
      .purchase-location-selector__wrapper,
      .RegisterProductForm__purchase-date-wrapper {
        .intl-text {
          display: block;

          .Select-control {
            text-align: right;
          }
        }
      }
    }
  }

  .account-content {
    .AddressForm__text {
      text-align: right;
    }

    .AccountPage.AddressForm__text {
      transform: scaleX(-1);
    }

    .Address__heading {
      text-align: right;
    }

    .Address__details {
      text-align: right;
    }
  }

  .AgeVerificationPage {
    .DobFieldGroup__fields,
    .NameFieldGroup__fields {
      direction: rtl;
    }
  }

  .hero__content--devices {
    @include breakpoint(tablet) {
      margin-right: 8.333vw;
    }
  }

  .flash-messages {
    display: block !important;
  }

  .PasswordInput__toggle {
    left: $form-field-horizontal-padding;
    right: auto;
  }

  .indicator {
    margin-left: 10px;
    margin-right: 0;
  }

  .flash__link {
    margin-left: 0;
    margin-right: 30px;
  }

  .Address__inline .form-group + .form-group {
    @include breakpoint(tablet) {
      margin-left: 0;
      margin-right: 6px;
    }
  }

  .form-control.is-invalid {
    background-position: left 0.5em center;
  }

  .Checkout {
    input {
      &:disabled,
      &[readonly] {
        background-position: left 0.5em center !important;
      }
    }
  }

  .CheckoutPage__content-wrapper {
    direction: rtl;
  }

  .CheckoutPaymentMethod__icons {
    padding-right: 0;
  }

  .page-hero,
  .home-journey__title {
    direction: rtl;
  }

  .account-navigation-link {
    text-align: right;
  }

  .page-hero__content {
    text-align: right;
  }

  .product-page__wrapper {
    direction: rtl;
  }

  .product-page__product-selector {
    direction: rtl;
  }

  .hero__content {
    margin-right: 8.333vw;
  }

  .hero {
    direction: rtl;
  }

  .product-page__content__wrapper {
    direction: rtl;
  }

  .product-text-block__content--first--single {
    padding-right: 4.333vw;
  }

  .hero-action-box--single {
    direction: rtl;
  }

  .hero__title-pods {
    margin-left: 30px;
    margin-right: 0;
  }

  .product-text-block {
    margin-right: 8.333vw;
  }

  .product-text-block__text {
    text-align: right;
  }

  .product-page__content__wrapper {
    .intl-text {
      text-align: right;
    }
  }

  .home-newsletter__tablet-title-section {
    padding-right: 50px;
    text-align: right;
  }

  @media only screen and (max-width: $screen-md-min) {
    .home-newsletter__tablet-title-section {
      padding-right: 0;
      text-align: center;
    }
  }

  .support-page {
    .col-sm-offset-2 {
      margin-right: 16.66666667%;
    }

    .col-sm-offset-1 {
      margin-right: 8.33333333%;
    }

    .support-row {
      direction: rtl;
    }
  }

  .ProfileOverview__email-row,
  .RegisteredProducts {
    padding-right: 60px;
  }

  button.ProfileOverview__button--Newsletter {
    transform: none !important;
  }

  .intl-text {
    direction: rtl;
    display: inline-block;
  }

  input,
  button,
  select {
    direction: rtl !important;
  }

  .mini-header__title,
  .BackButton__label,
  .RegisterProductForm__label {
    direction: rtl;
  }

  .header__navbar .header__cart {
    border-left: 0;
    border-right: 0.5px solid $DEPRECATED_light-blue-grey;
  }

  .flip-x {
    transform: scaleX(-1);

    .intl-text {
      transform: scaleX(-1);
    }

    input,
    button,
    select {
      transform: scaleX(-1);
    }

    img {
      transform: scaleX(- 1);
    }

    .CheckoutPaymentMethod__icon {
      display: inline-block;
      transform: scaleX(-1);
    }

    .recurly-hosted-field {
      transform: scaleX(-1) !important;
    }

    .invalid-feedback {
      transform: scaleX(-1);
    }

    .Checkout input {
      background-position: left 0.5em center;
    }

    .product-page__membership-banner {
      position: inherit;
    }

    .ProfileOverview__value {
      text-align: right;
      transform: scaleX(-1);
    }

    .ProfileOverview__title {
      text-align: right;
      transform: scaleX(-1);
    }

    .OrderListItem__meta-value {
      text-align: right;
      transform: scaleX(-1);
    }

    .header__cart,
    .AddToCartButton,
    .QuantityInput__value {
      transform: scaleX(- 1);
    }

    .mini-header__title,
    .BackButton__label,
    .RegisterProductForm__label {
      display: inline-block;
      transform: scaleX(-1);
    }
  }

  .ProfileOverview {
    .Address__heading {
      text-align: right;
      transform: none;
    }
  }

  .ProfileOverview__title {
    margin-right: 8.33333333%;
  }

  .AddressForm {
    .intl-text {
      display: unset;
    }

    .Select-control {
      text-align: right;
    }
  }

  .PageHeader {
    direction: ltr;
  }

  .search__button {
    left: 1px;
    right: auto;

    @include breakpoint(desktop) {
      left: 5px;
    }
  }

  .scalable-content-section {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      direction: rtl;
    }
  }

  .content-section {
    flex-direction: row-reverse;
  }

  .content-section--align--left {
    text-align: right;
  }

  .page-hero__content-align--left {
    text-align: right;
  }

  .restricted-three-column-section {
    flex-direction: row-reverse;
  }

  .footer {
    direction: rtl;
  }

  .checkbox-group--indented {
    margin-right: 1em;
  }
}
