.community-content-carousel-section {
  max-width: 100vw;
  padding-right: 0;
}

.community-content-carousel__title {
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: 1.2px;
  margin: 0 0 12px;
}

.community-content {
  display: grid;
  background-color: $white;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  border-radius: 2px;
  border: solid 1px $grey2;
  box-sizing: border-box;
  width: 270px;
  margin: $spacing-xs 0 $spacing-xs $spacing-sm;

  @include breakpoint(desktop) {
    margin-left: 0;
    margin-right: $spacing-md;
  }

  @include breakpoint(desktop-xlarge) {
    margin-right: $spacing-lg;
  }
}

.community-content__image-box {
  width: 100%;
  overflow: hidden;
  height: 255px;
}

.community-content__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.community-content__lower-section {
  position: relative;
  padding: $spacing-sm $spacing-md 0;
  height: 200px;
}

.community-content__quote {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 0.5px;
  position: relative;
  margin-bottom: $spacing-sm;

  // Quotes
  &::before {
    display: block;
    padding-left: $spacing-xs;
    content: '\201C';
    font-size: inherit;
    position: absolute;
    left: -20px;

    @include breakpoint(desktop-xlarge) {
      padding-left: 5px;
    }
  }

  &::after {
    display: inline-block;
    content: '\201D';
    font-size: inherit;
    position: absolute;
  }
}

.community-content__user {
  position: absolute;
  bottom: $spacing-sm;
}

.community-content__user__name {
  font-size: 14px;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
}

.community-content__user__subtitle {
  font-size: 11px;
  text-transform: uppercase;
}

.community-content-carousel {
  max-width: 100vw;

  .slick-track {
    // display flex to fix the issue of images wrapping to a new line
    display: flex;
    align-items: center;
    visibility: hidden;
  }
  // prevents display of slides/glitch before initilization
  &.slick-initialized {
    .slick-track {
      display: flex;
      visibility: visible;
    }
  }

  .slick-list {
    width: 100%;
  }

  .slick-arrow {
    height: fit-content;
    width: fit-content;
    z-index: 1;

    &.slick-next,
    &.slick-prev {
      position: absolute;
      display: block !important;
      top: -20px !important;
    }

    &.slick-next {
      right: 0 !important;
    }

    &.slick-prev {
      left: 0 !important;

      svg {
        transform: scaleX(-1);
      }
    }
  }
}
