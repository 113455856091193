.price-tag--compact {
  background-color: transparent;
  letter-spacing: initial;
  padding: 0;
}

.price-tag__standard {
  font-weight: bold;
}

.price-tag__standard--discounted {
  font-weight: normal;
  padding-left: 6px;
}

.price-tag__standard--strikethrough {
  font-weight: normal;
  text-decoration: line-through;
}

.price-tag__standard--auto-ship {
  color: $autoship-green;
}

.price-tag__discount {
  font-weight: bold;
  color: $black;
}

.price-tag__discount--auto-ship {
  color: $autoship-green;
}
