.quote-carousel-section {
  margin-left: 0;
  margin-right: 0;
}

.quote-carousel {
  max-width: 100vw;

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-next::before,
  .slick-prev::before {
    display: none;
  }

  .slick-slide {
    margin: 0 $spacing-md;
  }

  .slick-arrow {
    height: fit-content;
    width: fit-content;
    position: absolute;
    z-index: 1;

    &.slick-prev .carousel-arrow {
      left: 0;
      transform: rotate(360deg) scaleX(-1);
    }

    &.slick-next .carousel-arrow {
      right: 0;
      transform: rotate(180deg) scaleX(-1);
    }
  }

  .slick-dots {
    li {
      margin: 0;
    }
  }
}

.quote-carousel__title {
  text-align: center;
  margin-bottom: $spacing-md;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: bold;
  text-transform: uppercase;
}

.quote-card__content {
  text-align: center;

  @include breakpoint(tablet) {
    margin: 0 $spacing-lg;
  }
}

.quote-card__text {
  margin: 0 0 $spacing-sm;

  @include fluid-type(32px, 60px);

  font-weight: bold;
  line-height: 1.11;

  @include breakpoint(desktop-large) {
    line-height: 1.03;
  }
}

.quote-card__subcontent {
  font-size: 14px;
  margin: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1.2px;

  @include breakpoint(desktop) {
    > span {
      display: inline-block;
      margin: $spacing-md $spacing-xs;
    }
  }
}

.quote-card__identifier {
  display: block;
  font-weight: bold;
}

.quote-card__subtitle {
  display: block;
  font-weight: 300;
}
