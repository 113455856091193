@import '../core';

.tooltip {
  position: relative;
}

.tooltip__trigger {
  display: inline-block;
}

.tooltip__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;

  // ReachUI does not support touch events as they are currently an accessibilty
  // concern for tooltips. They are hoping to audit this approach mid-2020, and
  // we should check back then! If you *need* to display information to a user
  // then a tooltip might not be what you're looking for; try using a modal or
  // inlining the content directly on the page.
  @media (any-hover: none) {
    display: none;
  }
}

.tooltip__popup {
  background-color: $white;
  color: $black;
  border: 0;
  padding: 0;
  box-shadow: 0 2px 10px 0 rgba($black, 0.1);
  white-space: normal;
  max-width: 304px;
  z-index: 5001;
}

.tooltip__triangle {
  position: fixed; // positioning is determined dynamically in Tooltip.js
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%) rotate(45deg);
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  z-index: 1; // Sit under .tooltip__message
}

.tooltip__message {
  position: relative;
  display: block;
  padding: 16px;
  background-color: $white;
  z-index: 2; // Sit above .tooltip__triangle
}
