@import '../core';
@import '../business/common/components/modal/modal';

.fade-animation {
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  visibility: hidden;

  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

.loading__overlay {
  background: $black;
  height: 125vh;
  opacity: 0;
  padding-top: 40vh;
  position: absolute;
  text-align: center;
  transition: visibility 0.1s linear, opacity 0.1s linear;
  vertical-align: center;
  visibility: hidden;
  width: 100%;
  z-index: 100;

  @include breakpoint(desktop) {
    height: 100vh;
  }

  &--visible {
    opacity: 0.9;
    visibility: visible;
  }

  h3 { color: $white; }
}

.locator__container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-nav-xs-height});

  @include breakpoint(tablet) {
    flex-direction: row;
    height: calc(100vh - #{$header-nav-sm-height});
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
  }
}

.sidebar__wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;

  @include breakpoint(tablet) {
    max-width: 360px;
    margin-right: $spacing-lg;
    height: 100%;
  }

  @include breakpoint(desktop) {
    max-width: 501px;
  }
}

.map__wrapper {
  display: flex;
  flex-grow: 1;
  margin-left: -$spacing-md;
  margin-right: -$spacing-md;
  width: calc(100% + #{$spacing-md * 2});

  @include breakpoint(tablet) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .locator__container--results-available & {
    @include breakpoint(mobile) {
      display: none;
    }
  }
}

.map__canvas {
  background: $grey2;
  width: 100%;
}

.search__wrapper {
  padding: $spacing-sm 0;

  @include breakpoint(tablet) {
    border-bottom: 1px solid $grey2;
    padding: 0 0 $spacing-sm 0;
  }
}

$filter-icon-x: 53px;

#search_form {
  display: inline-block;
  width: 100%;
  position: relative;

  .text-input {
    margin: 0;
  }
}

.map__marker {
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  top: -25px;
  cursor: pointer;
  transition: width 0.5s, height 0.5s;
  z-index: 1;

  &--my-location {
    width: 25px;
    height: 25px;
    left: -12px;
    top: -12px;
  }

  &--inactive {
    width: 30px;
    height: 30px;
    left: -15px;
    top: -15px;
    z-index: 0;
  }

  &.map__marker--juul-retail-store {
    width: 60px;
    height: 60px;
    left: -30px;
    top: -30px;
    z-index: 2;

    &.map__marker--inactive {
      width: 40px;
      height: 40px;
      left: -20px;
      top: -20px;
    }
  }
}

// Negative margin to have this container break out of the .content-wrapper
// and go full-width on mobile only.
.results__container {
  overflow-y: auto;
  position: relative;
  margin-left: -$spacing-md;
  margin-right: -$spacing-md;
  width: calc(100% + #{$spacing-md * 2});

  @include breakpoint(tablet) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.card__container {
  border-top: 1px solid $grey2;
  cursor: pointer;
  padding: $spacing-md;
  padding-right: 80px;
  position: relative;

  @include breakpoint(tablet) {
    border: 1px solid $grey2;
    border-radius: 4px;
    margin-top: $spacing-xs;
  }

  .card-details__ribbon-container {
    position: absolute;
    top: 0;
    right: $spacing-md;

    .card-details__ribbon {
      position: relative;
    }

    .card-details__icon {
      position: absolute;
      inset: 0;
      margin: auto;
    }
  }
}

.card__container:hover {
  box-shadow: inset 4px 0 0 0 $black;
}

.card-details__name {
  margin: 0 0 $spacing-xs 0;
  overflow: hidden;
}

.card-details__line-container {
  margin-top: $spacing-xs;

  &.address {
    text-transform: capitalize;
  }
}

.details-panel__container {
  padding: 0 $spacing-sm $spacing-sm $spacing-sm;

  @include breakpoint(tablet) {
    padding: 0;
  }
}

.details-panel__header {
  padding-top: $spacing-sm;
  border-top: 1px solid $grey2;
}

.details-panel__close {
  cursor: pointer;
  font-size: 20px;
  padding: $spacing-sm $spacing-sm $spacing-sm 0;
  display: inline-flex;
  align-items: center;

  img {
    margin-right: $spacing-xs;
  }
}

.details-panel__store-information {
  .subheading--small {
    padding-top: $spacing-xs;
  }

  h5 {
    margin: 0;
  }
}

.details-panel__store-information-section {
  margin-top: $spacing-md;

  .subheading {
    margin: $spacing-xs 0;
  }
}

.details-panel__address {
  font-size: 16px;
  margin: $spacing-sm 0;
}

.details-panel__hours {
  margin-top: $spacing-md;
}

.hours__wrapper h5 {
  border-bottom: 1px solid $grey2;
  padding: 2px 4px;
}

.hours__table .day {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: $spacing-xs 0;
  border-top: 1px solid $grey2;
}

.hours__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacing-sm;

  .hours__open-now {
    color: $autoship-green;
  }
}

.hours__table .day__name {
  flex: 1;
}

.hours__table .day__status {
  flex: 2;
  text-align: right;
}

.autocomplete-dropdown-container {
  &__wrapper {
    position: relative;
    z-index: 10;
  }

  &__suggestions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: white;
    border: 1px solid $black;
    border-top: none;
    border-radius: 2px;
  }
}

.suggestion-item {
  color: $grey4;
  padding: 10px 15px;

  &__matched {
    color: $black;
    font-weight: bold;
  }

  &--active {
    background-color: $grey1;
  }
}

@include breakpoint(mobile) {
  .locator__container--details-selected {
    .results__wrapper {
      max-height: 100vh;
    }

    .search__wrapper {
      display: none;
    }
  }

  .card-details__isOpen .no-info {
    display: none;
  }
}

.no-results-information__container {
  margin-top: $spacing-sm;
  display: none;

  @include breakpoint(tablet) {
    display: block;
  }
}

.no-results-information__not-found-text {
  color: $grey5;

  a {
    color: $blue-base-100;
  }
}

.filter-icon {
  // We're temporary hidding the filter icon as feature is not complete yet.
  display: none;
  width: $spacing-md;
  height: $spacing-md;
  margin: 14px 5px 14px $spacing-md;
  cursor: pointer;
}

.filter-modal-options {
  width: 100%;
  padding: $spacing-sm;

  @include breakpoint(tablet) {
    max-width: 395px;
    padding: 0;
  }
}

.filter-modal-option {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-sm 0;
  border-bottom: 1px solid $grey2;

  &__label {
    margin: 0;
    letter-spacing: 0;
  }

  &__icon {
    width: 20px;
    height: 20px;

    // These are image elements so we do not have the ability to change their fill
    // values
    filter: brightness(0) invert(1);
  }

  &:last-child {
    border-bottom: none;
  }
}
