.rtl-direction .collection-section {
  direction: rtl;
}

// We need to use an !important tag here so that we override any of the options
// in child components. This is a simpler approach than making the components
// be able to understand options defined in each other.
@each $alignment in (start, center) {
  .collection-section--alignment-#{$alignment} *:not(.button) {
    text-align: $alignment !important;
  }
}

.collection-section__items {
  display: grid;
  grid-gap: 32px;

  // Only enable multiple items per row on devices that can accommodate it
  @include breakpoint(tablet) {
    &--2-per-row {
      grid-template-columns: repeat(2, 1fr);
    }

    &--3-per-row {
      grid-template-columns: repeat(3, 1fr);
    }

    &--4-per-row {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.collection-section__items--mobile-columns {
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.collection-section__item {
  display: contents;

  > div {
    display: contents;
  }
}

.entry-content + .collection-section__items {
  margin-top: 32px;
}

.collection-section--alignment-center .entry-content__call-to-actions {
  align-items: center;
}
