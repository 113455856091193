.list {
  margin: 8px 0 0;
}

.list__item {
  list-style-image: url('/images/icons/icon-plus-2.svg');
  margin-bottom: 8px;
  padding-left: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
