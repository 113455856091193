@import '../core';

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-padding: 120px 0 0; // a rough approximation of our fixed header
}

img {
  max-width: 100%;
}

blockquote {
  margin: 0;
}

.body {
  background: $base-background;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.body__content {
  @include DEPRECATED_flex(1);

  min-height: 100vh;
  padding-top: $header-nav-xs-height;

  @include breakpoint(tablet) {
    padding-top: $header-nav-sm-height;
  }

  &.body__content--no-header {
    padding-top: 0;
  }
}

.header__overlay {
  background: rgba($black, 0.7);
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.header__overlay--visible {
  display: block;
}

.header__overlay--full-height {
  top: 0;
  z-index: zindex(cart-mobile) - 1;
}

.container,
.container-fluid {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.container-fluid {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.hide {
  display: none !important;
}

.hide-tablet {
  @include breakpoint(tablet) {
    display: none !important;
  }
}

.hide-mobile {
  @include breakpoint(mobile) {
    display: none !important;
  }
}

.invisibile {
  visibility: hidden !important;
}

.invisibile-tablet {
  @include breakpoint(tablet) {
    visibility: hidden !important;
  }
}

.invisibile-mobile {
  @include breakpoint(mobile) {
    visibility: hidden !important;
  }
}

.show-tablet {
  display: none;

  @include breakpoint(tablet) {
    display: initial;
  }
}

.show {
  display: block !important;
}

// Hide elements while still making them accessible to screen readers.
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.height-100 {
  height: 100%;
}

.no-padding {
  padding: 0;
}

.flex {
  display: flex;
}

.flex-dir-col {
  flex-direction: column;
}

.no-scroll {
  overflow: hidden;
}

.no-margin {
  margin: 0;
}

.impersonate {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  text-decoration: underline;
  z-index: 100;
}

// Wrapper with padding for top elements

.content-wrapper {
  width: 100%;
  max-width: $layout-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-md;
  padding-right: $spacing-md;

  @include breakpoint(tablet) {
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }

  @include breakpoint(desktop) {
    padding-left: $spacing-xxl;
    padding-right: $spacing-xxl;
  }

  @include breakpoint(desktop-xlarge) {
    padding-left: $spacing-xxxxl;
    padding-right: $spacing-xxxxl;
  }
}

// Flexboxgrid alignment class overwrite

[class^='start-'],
[class^='center-'],
[class^='end-'] {
  text-align: initial;
}

// Flexboxgrid gutters overwrite

.container,
.container-fluid {
  padding-left: $spacing-sm / 2;
  padding-right: $spacing-sm / 2;

  @include breakpoint(desktop) {
    padding-left: $spacing-md / 2;
    padding-right: $spacing-md / 2;
  }

  @include breakpoint(desktop-xlarge) {
    padding-left: $spacing-lg / 2;
    padding-right: $spacing-lg / 2;
  }
}

.row {
  margin-left: -$spacing-sm / 2;
  margin-right: -$spacing-sm / 2;

  @include breakpoint(desktop) {
    margin-left: -$spacing-md / 2;
    margin-right: -$spacing-md / 2;
  }

  @include breakpoint(desktop-xlarge) {
    margin-left: -$spacing-lg / 2;
    margin-right: -$spacing-lg / 2;
  }
}

[class*="col-"] {
  padding-left: $spacing-sm / 2;
  padding-right: $spacing-sm / 2;

  @include breakpoint(desktop) {
    padding-left: $spacing-md / 2;
    padding-right: $spacing-md / 2;
  }

  @include breakpoint(desktop-xlarge) {
    padding-left: $spacing-lg / 2;
    padding-right: $spacing-lg / 2;
  }
}
