.restricted-three-column-section {
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}

.restricted-three-column-section__item {
  text-align: center;
  margin-bottom: $spacing-md;

  @include breakpoint(desktop) {
    margin-bottom: 0;
  }
}

.restricted-three-column-section__item__text {
  padding: 0 $spacing-md $spacing-md $spacing-md;

  @include breakpoint(tablet) {
    padding: 0 $spacing-xl $spacing-lg $spacing-xl;
  }

  @include breakpoint(desktop) {
    padding-bottom: $spacing-lg;
  }
}
