.mini-navigation-steps {
  display: flex;
  border-bottom: 1px solid $grey2;
  overflow-x: auto;
}

.mini-navigation-steps__scroll-container {
  display: flex;
  margin: auto;
}

.mini-navigation-steps--start-aligned {
  margin-right: auto;
  margin-left: 0;
}

.mini-navigation__step {
  padding: 16px 0 18px;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;

  & + & {
    margin-left: 16px;
  }

  &--selected {
    text-decoration: underline;
  }
}

.mini-navigation__step--no-underline {
  text-decoration: none;
}

.mini-navigation__step--disabled {
  color: $grey3;
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}

.mini-navigation__step--with-pointer {
  cursor: pointer;
}
