@import "../core";

/**
 Based on https://github.com/mohsinulhaq/react-popper-tooltip/blob/master/src/styles.css
 */

.common-hint {
  position: relative;
  text-align: left;
  z-index: 20;
  color: white;

  &__text {
    padding-right: 24px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
  }

  &__actions {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .common-small-button + .common-small-button {
      margin-left: 8px;
    }

    .common-small-button {
      white-space: nowrap;
    }
  }
}

.common-hint-tooltip {
  --tooltipBackground: #{$black};
  --tooltipBorder: #{$black};
  --tooltipColor: #{$white};

  visibility: hidden;
  pointer-events: none;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--tooltipBackground);
  border: 1px solid var(--tooltipBorder);
  border-radius: 4px;
  color: var(--tooltipColor);
  padding: 16px;
  transition: opacity 0.3s;
  max-width: 305px;
  z-index: map-get($z-index-map, sidenav) - 1;

  &[data-popper-reference-hidden] {
    visibility: visible;
    pointer-events: auto;
  }

  &--primary {
    --tooltipBackground: #{$blue-base-100};
    --tooltipBorder: #{$blue-base-100};
  }
}

.common-hint-tooltip[data-popper-interactive='false'] {
  pointer-events: none;
}

.common-hint-tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;
}

.common-hint-tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.common-hint-tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.common-hint-tooltip[data-popper-placement*='bottom'] .common-hint-tooltip-arrow {
  left: 0;
  margin-top: -0.39rem;
  top: 0;
}

.common-hint-tooltip[data-popper-placement*='bottom'] .common-hint-tooltip-arrow::before {
  border-color: transparent transparent var(--tooltipBorder) transparent;
  border-width: 0 0.5rem 0.4rem;
  position: absolute;
  top: -1px;
}

.common-hint-tooltip[data-popper-placement*='bottom'] .common-hint-tooltip-arrow::after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 0.5rem 0.4rem;
}

.common-hint-tooltip[data-popper-placement*='top'] .common-hint-tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.common-hint-tooltip[data-popper-placement*='top'] .common-hint-tooltip-arrow::before {
  border-color: var(--tooltipBorder) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0;
  position: absolute;
  top: 1px;
}

.common-hint-tooltip[data-popper-placement*='top'] .common-hint-tooltip-arrow::after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0;
}

.common-hint-tooltip[data-popper-placement*='right'] .common-hint-tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.common-hint-tooltip[data-popper-placement*='right'] .common-hint-tooltip-arrow::before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.common-hint-tooltip[data-popper-placement*='right'] .common-hint-tooltip-arrow::after {
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.common-hint-tooltip[data-popper-placement*='left'] .common-hint-tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}

.common-hint-tooltip[data-popper-placement*='left'] .common-hint-tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.common-hint-tooltip[data-popper-placement*='left'] .common-hint-tooltip-arrow::after {
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
