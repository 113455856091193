$screen-width-large-icons: 800px;

.product-navigation {
  margin-top: 24px;

  .rtl-direction & {
    direction: rtl;
  }
}

.product-navigation__items-wrapper {
  overflow: auto;
}

// This extra element is required so that the padding of the parent wrapper is
// respected even if the element scrolls
.product-navigation__items-inner {
  display: inline-block;
}

.product-navigation__items {
  display: flex;
  margin: 8px 0;
}

.product-navigation__item {
  width: 104px;
  flex-shrink: 0;
  padding: 6px 4px 10px;
  border: 1px solid $grey2;
  border-radius: 4px;
  text-align: center;

  &,
  &:hover {
    text-decoration: none;
  }

  &:focus {
    border-radius: 4px;
  }

  &:hover {
    border-color: $black;
  }

  &:focus {
    outline-offset: -2px;
  }

  & + & {
    margin-left: 8px;

    .rtl-direction & {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  @media screen and (min-width: $screen-width-large-icons) {
    width: 128px;
    padding-left: 8px;
    padding-right: 8px;

    & + & {
      margin-left: 12px;

      .rtl-direction & {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }
}

.product-navigation__item-image {
  display: block;
  margin: auto;
  height: 54px;

  @media screen and (min-width: $screen-width-large-icons) {
    height: 86px;
  }
}

.product-navigation__item-text {
  margin-top: 6px;
}
