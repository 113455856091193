[data-testid='radio-group'] {
  label {
    text-transform: unset;
    display: unset;
    margin-bottom: unset;
    font-size: unset;
    font-weight: unset;
    letter-spacing: unset;
  }
}
