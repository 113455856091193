.media-with-text-entry-grid {
  @include fluid-type(
    32px,
    80px,
    $properties: ('margin-top', 'margin-bottom')
  );

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  text-align: center;

  @include breakpoint(tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @include isIE11() {
    display: flex;
  }
}

.media-with-text-entry__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  align-items: center;

  @include breakpoint(tablet) {
    &:not(.media-with-text-entry__wrapper--grid-child) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

// IE 11 does not support `display: grid` but we can provide some fallbacks that
// make it look somewhat reasonable.
.media-with-text-entry__wrapper:not(.media-with-text-entry__wrapper--grid-child) {
  @include isIE11() {
    display: flex;
    justify-content: space-between;
  }

  .media-with-text-entry__media,
  .media-with-text-entry__content {
    @include isIE11() {
      width: calc(50% - 16px);
    }
  }
}

.media-with-text-entry__media--end {
  @include breakpoint(tablet) {
    order: 1;
  }
}

.media-with-text-entry__content--grid-child-with-padding {
  padding: 0 24px 24px;
}

// Even though we've set this component to not have padding, on mobile we need
// to add some bottom padding so that the content doesn't butt up against the
// edge of the component.
.media-with-text-entry__content--full-bleed-without-padding {
  @include breakpoint(mobile) {
    padding-bottom: 32px;
  }
}

// When the container has the padding stripped away we need to make sure we're
// adding padding around the content itself so that it doesn't butt up against
// the edges. This assumes that the section is using a background color
// different to the body element.
.media-with-text-entry__content--constrained-without-padding {
  padding: 0 24px 24px;

  @include breakpoint(tablet) {
    padding: 0 24px 0 0;

    .media-with-text-entry__media--end + &,
    .rtl-direction & {
      padding: 0 0 0 24px;
    }

    .rtl-direction .media-with-text-entry__media--end + & {
      padding: 0 24px 0 0;
    }
  }
}

.media-with-text-entry__wrapper--grid-child .entry-content__call-to-actions {
  @include breakpoint(tablet) {
    align-items: center;
  }
}
