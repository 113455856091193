.toggle {
  position: relative;
}

.toggle__button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 56px;
  height: 24px;
  padding: 0 6px;
  border: 0;
  border-radius: 16px;
  background-color: $grey5;
  color: $white;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  appearance: none;

  &[aria-pressed="true"] {
    justify-content: flex-start;
    background-color: $autoship-green;
  }

  .rtl-direction & {
    direction: rtl;
  }
}

.toggle__button::before {
  content: '';
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $white;
  top: 2px;
  left: 2px;
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out;

  .rtl-direction & {
    left: auto;
    right: 2px;
  }
}

.toggle__button[aria-pressed="true"]::before {
  left: calc(100% - 22px);

  .rtl-direction & {
    left: auto;
    right: calc(100% - 22px);
  }
}

.toggle__label-content {
  display: flex;
}
