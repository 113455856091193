@import '../core';

.age-gate__buttons {
  display: flex;
  flex-direction: column;
}

.age-gate__primary-button {
  margin-bottom: 20px;
}

.age-gate__body {
  justify-content: space-around;

  @include breakpoint(tablet) {
    justify-content: space-between;
  }
}

#age-gate .Modal__footer {
  max-width: 700px;

  @include breakpoint(mobile) {
    padding: 10px;
  }
}

// TODO: this should be pulled out into a global style for select elements
.age-gate-states-wrapper {
  position: relative;
  max-width: 360px;
  margin: 16px auto;

  &::after {
    content: url('/images/icons/select-arrow.svg');
    width: 10px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

#age-gate-states {
  width: 100%;
  margin: 0;
  padding: 11px 42px 12px 16px; // extra right padding for the arrow icon
  font-family: $base-font-family;
  border-radius: 0;
  border: 1px solid $grey2;
  background-color: $white;

  // IE11 fix to avoid showing 2 arrows
  &::-ms-expand {
    display: none;
  }

  &:focus,
  &:active {
    border-color: $grey5;
  }
}

#age-gate-blocked-state-warning {
  background-color: rgba($error-red, 0.1);
  margin: 8px auto;
  padding: 16px;
}
