@import '../core';

html .translation-missing {
  background-color: red;
  border: 1px solid red;
}

html[lang|="ko"],
html[lang|="ja"] {
  .intl-text {
    word-break: keep-all;
  }

  .scalable-content-section {
    word-break: keep-all;
  }

  .marketing-page {
    word-break: keep-all;
  }
}
