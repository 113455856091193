@import '../core';

.inline-notification {
  border: 1px solid $grey1;
  background-color: $grey1;
  border-radius: 2px;
  padding: $spacing-sm;
}

.inline-notification--with-icon {
  display: flex;
}

.inline-notification--error {
  border-color: $error-red;
  background-color: rgba($error-red, 0.1);
  color: $error-red;
}

.inline-notification--valid {
  background: #effafa;
  color: #0a7f7b;
  border-color: #0a7f7b;
}

.inline-notification__icon {
  align-self: flex-start;
  margin-top: 0;
  margin-right: $spacing-xs;
  float: left; // IE11
  height: 24px;
  min-width: 24px;
}

.inline-notification__title {
  display: block;
  margin-bottom: 4px;
}

.inline-notification__body {
  p {
    display: contents;
  }

  p::after {
    display: block;
    content: '';
  }
}

.inline-notification__call-to-action {
  padding-top: 12px;
  display: block;
}
