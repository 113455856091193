.color-swatch__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
}

.color-swatch {
  display: inline-block;
  height: 32px;
  width: 32px;
  border: 3px solid $white;
  border-radius: 50%;
  appearance: none;
  outline: none;

  :focus + & {
    @include focus-visible-outline();
  }

  :focus:not([data-focus-visible-added]) + & {
    outline: none;
  }
}

.color-swatch--selected {
  box-shadow: 0 0 0 1px $black;
}

.color-swatch__name {
  margin-top: 4px;
  text-align: center;
}
