@import '../core';

.page-hero {
  @include DEPRECATED_flexbox(row, center, center);

  height: 476px;

  @include breakpoint(desktop) {
    height: 520px;
  }

  overflow: hidden;
  background-color: $grey3;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include breakpoint(tablet) {
    @include DEPRECATED_flexbox(column, center, normal);

    min-width: 100%;
    width: 100%;
  }
}

.page-hero__image {
  > img {
    @include position-absolute((
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 100%,
      height: 100%,
    ));

    object-fit: cover;
  }
}

.page-hero__background-image {
  @include position-absolute((
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 100%,
    height: 100%,
  ));

  background-size: cover;
  background-color: $grey3;
}

.page-hero__video-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $black;
  overflow: hidden;

  @include breakpoint(mobile) {
    display: none;
  }
}

.page-hero__video {
  height: 100%;
  width: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.page-hero__content {
  margin: 0 8.33%;
  z-index: 2;

  @include breakpoint(tablet) {
    margin-bottom: 0;
    max-width: 415px;
  }

  @include breakpoint(desktop-xxlarge) {
    max-width: 16vw;
  }
}

.page-hero__content h1 {
  margin-top: 0;
}

.page-hero__text {
  margin-top: 10px;
}

.page-hero__buttons {
  margin-top: 45px;

  @include breakpoint(tablet) {
    margin-top: 22px;
  }
}

.page-hero__button {
  width: 100%;

  @include breakpoint(tablet) {
    &:first-child:last-child {
      width: auto;
    }
  }
}

.page-hero__special {
  &.page-hero__special-offer {
    margin-bottom: 12px;
    padding-left: 2px;
    text-transform: Uppercase;
  }

  &.page-hero__special-text {
    margin: 0;
  }
}
