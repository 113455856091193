.product-selector__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;

  .rtl-direction & {
    direction: rtl;
  }
}

.product-selector__title {
  margin-bottom: $spacing-xs;
}

.product-selector-option__container {
  margin-bottom: 0;
  position: relative;

  & + &::before {
    content: '';
    position: absolute;
    display: inline-block;
    height: 80%;
    border: 1px solid $grey1;
  }

  & + & .product-selector-option {
    margin-left: 8px;
  }

  .rtl-direction & + & .product-selector-option {
    margin-right: 8px;
    margin-left: 0;
  }
}

.product-selector-option {
  display: inline-block;
  border-bottom: 6px solid transparent;
  appearance: none;
  outline: none;
  text-align: left;
  cursor: pointer;

  .rtl-direction & {
    text-align: right;
  }
}

.product-selector-option--selected {
  border-bottom-color: $black;
}

.product-selector-option__title {
  font-size: 18px;
}

.product-selector-option__sub-text {
  margin-top: 6px;
  margin-bottom: 6px;
}
