.device-serial-number-locator {
  display: inline-flex;
  align-items: center;

  .rtl-direction & {
    direction: rtl;
    flex-direction: row-reverse;
  }
}

.device-serial-number-locator__label {
  font-weight: bold;
  color: #0a72ec;
  margin-left: 4px;
}

.device-serial-number-locator__image {
  width: 220px;
}
