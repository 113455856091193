$snow: #f9f9f9;
$cloud: #fafafc;
$steel: #1a1a1c;

@function get-color($color, $opacity) {
  @return transparentize($cloud, 1 - $opacity * 0.01);
}

.CookieDeclaration {
  display: none;
}

#CybotCookiebotDialog {
  font-weight: 400 !important;
  letter-spacing: normal !important;

  &[data-template='slideup'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    a {
      font-size: 13px;
      line-height: 18px;

      @include breakpoint(tablet) {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }

  #CybotCookiebotDialogBodyContent {
    @include breakpoint(mobile) {
      padding-bottom: 0 !important;
    }
  }

  #CybotCookiebotDialogBodyContentText {
    font-size: 13px;
    line-height: 18px;

    @include breakpoint(tablet) {
      font-size: 17px !important;
      line-height: 24px !important;
    }
  }

  #CybotCookiebotDialogBodyContentText a,
  #CybotCookiebotDialogBodyContentText a:hover,
  a,
  a:hover {
    font-weight: normal !important;
    color: $snow !important;
    text-decoration: underline !important;
  }

  .CybotCookiebotDialogNavItem a {
    text-decoration: none !important;
  }

  h2#CybotCookiebotDialogBodyContentTitle {
    font-size: 17px !important;
    line-height: 24px !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerHeader button {
    display: flex;

    label {
      text-transform: none;
      letter-spacing: normal;
      font-weight: 700;
    }
  }

  span.CybotCookiebotDialogDetailBulkConsentCount {
    background-color: get-color($cloud, 35);
  }

  #CybotCookiebotDialogTabContent
  input[type='checkbox'][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: get-color($steel, 15);

    &::before {
      background-color: get-color($cloud, 35);
    }
  }

  #CybotCookiebotDialogTabContent
  input[type='checkbox']:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: get-color($cloud, 35);

    &::before {
      background-color: $cloud;
    }
  }

  #CybotCookiebotDialogTabContent
  input[type='checkbox']:not(:checked) + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: get-color($steel, 30);

    &::before {
      background-color: $cloud;
    }
  }

  button.CybotCookiebotDialogBodyButton {
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    border-width: 1px;
    padding: 16px;
    align-items: center;

    &:hover {
      border-color: get-color($cloud, 50) !important;
    }

    &:active {
      border-color: get-color($cloud, 25) !important;
    }

    &#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      &:hover {
        background-color: get-color($cloud, 90) !important;
      }

      &:active {
        background-color: get-color($cloud, 75) !important;
      }
    }
  }

  .CybotCookiebotDialogBodyBottomWrapper,
  #CybotCookiebotDialogHeader,
  #CybotCookiebotDialogPoweredByText {
    display: none !important;
  }

  #CybotCookiebotDialogTabContent {
    margin-left: 0 !important;
  }

  .CybotCookiebotScrollContainer {
    border: none !important;

    @include breakpoint(mobile) {
      min-height: unset !important;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 767px) {
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
      width: 100% !important;
    }

    &[data-template='popup']
    #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyButtonsWrapper {
      align-items: flex-end;
      flex-direction: column-reverse;
    }
  }

  @media screen and (min-width: 601px) {
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
      margin: 0 0 0.5em !important;
      max-width: 100%;
    }

    &[data-template='slideup'] {
      #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
        align-items: flex-end;
        flex-direction: column-reverse;
      }

      .CybotCookiebotDialogContentWrapper {
        flex-direction: row !important;
      }

      #CybotCookiebotDialogFooter {
        flex: 0 0;
        max-width: 33%;
        padding-top: 24px;
      }

      #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        width: 286px !important;
      }
    }
  }

  @include breakpoint(tablet) {
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
      margin: 0 0.5em 0.5em 0 !important;
    }
  }

  @media (max-width: 767px) {
    width: 100vw !important;
    left: 0 !important;
    top: unset !important;
    bottom: 0;
    transform: none !important;

    &[data-template='slideup'] {
      .CybotCookiebotFader {
        display: none;
      }

      #CybotCookiebotDialogBodyButtonDecline {
        display: none;
      }
    }

    &[data-template='popup'] {
      height: 100vh !important;
      border-radius: 0 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
      max-height: 50vh;
    }
  }

  @media (max-width: 1279px) {
    max-height: 100vh !important;

    &[data-template='slideup'] {
      max-width: 100vw !important;
      width: 100vw !important;
      left: 0 !important;
      top: unset !important;
      bottom: 0;
      transform: none !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
