// Brand
$black: #000;
$grey5: #424242;
$grey4: #797979;
$grey3: #9b9b9b;
$grey2: #e9e9e9;
$grey1: #f5f5f5;
$white: #fff;
$autoship-green: #009c83;
$error-red: #e4002b;
$lightThemeText: #c6c6c6;

// Product
$product-alpine-berry: #a6192e;
$product-apple-orchard: #44a705;
$product-c-tobacco: #864a33;
$product-creme: #efbe7d;
$product-cucumber: #319b42;
$product-fruit: #c00d1e;
$product-g-tobacco: #816040;
$product-glacier-mint: #00875d;
$product-mango: #f68d2e;
$product-menthol: #00bab3;
$product-mint: #00ab8e;
$product-royal-creme: #f1eb9c;
$product-ruby: #9e0426;
$product-virginia-tobacco: #472426;
$product-slate: #515b68;
$product-silver: #c1cdcd;

// Hyperlink and Hint messages or components
$blue-base-100: #0a72ec;

// JUUL Labs site
$color-juullabs: #004cc4;
