@import '../core.scss';

.content-section--align--left {
  text-align: left;
}

.content-section--align--center {
  text-align: center;
  justify-content: center;

  .scalable-content-section__links {
    justify-content: center;
  }
}

.content-section--width--small {
  @include breakpoint(tablet) {
    .content-section__content {
      max-width: 50%;
      flex-basis: 50%;
    }
  }
}

.content-section--width--medium {
  @include breakpoint(tablet) {
    .content-section__content {
      max-width: 66%;
      flex-basis: 66%;
    }
  }
}

.content-section--width--large {
  @include breakpoint(tablet) {
    .content-section__content {
      width: 83%;
      flex-basis: 83%;
    }
  }
}
