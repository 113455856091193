@import '../core';

.flash {
  background-color: $grey1;
  color: $grey5;
  padding: 12px 5px;
  position: relative;
  text-align: center;
  z-index: 2;

  &.alert,
  &.error,
  &.registration_error {
    background-color: $error-red;
    color: $white;
  }

  &.gdpr {
    padding: 24px 8.33%;
  }
}

.flash a {
  text-decoration: none;
}

.flash__content > p {
  display: inline;
  font-size: 14px;
}

a.flash__link {
  display: none;
  margin-left: 30px;
  vertical-align: middle;
  text-decoration: underline;

  .alert &,
  .error &,
  .registration-error & {
    border-color: $white;
  }

  @include breakpoint(tablet) {
    display: inline-block;
  }
}
