@use "~@juullabs/react-components/src/styles/atom";

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: atom.getColor(red);
  text-decoration: underline;
  z-index: 1000;
}
