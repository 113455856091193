@import "../core/variables";

.common-small-button {
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: white;
  text-decoration: none;
  padding: 8px 12px;
  color: $black;
  cursor: pointer;
  display: inline-block;

  &:hover,
  &:active {
    background-color: #f2f2f2;
    text-decoration: none;
  }

  &:disabled {
    pointer-events: none;
    background-color: $grey2;
    color: $grey4;
  }

  &--hint {
    color: $blue-base-100;

    &:hover {
      background-color: rgba($white, 0.8);
      color: #0967d4;
    }

    &:active {
      background-color: rgba($white, 0.5);
      color: #085bbd;
    }

    &:disabled {
      pointer-events: none;
      color: $blue-base-100;
      background-color: rgba($white, 0.15);
    }
  }

  &--light {
    color: $white;
    background-color: $black;

    &:hover,
    &:active {
      background-color: #262626;
    }
  }

  &--auto-ship {
    background-color: $autoship-green;
    color: $white;

    &:hover {
      color: $white;
      background-color: darken($autoship-green, 4.5%);
    }

    &:active {
      color: $white;
      background-color: darken($autoship-green, 9%);
    }
  }

  &--secondary {
    background-color: transparent;
    color: $white;

    &:hover {
      color: $white;
      background-color: rgba($white, 0.15);
    }

    &:active {
      color: $white;
      background-color: rgba($white, 0.3);
    }

    &:disabled {
      color: rgba($white, 0.15);
      background-color: transparent;
    }
  }
}
