// DEPRECATED MIXINS
//
// These mixins are deprecated and should no longer be used. In all cases there
// should be an alternative to use.

// TODO: remove all use cases
//
// Alternative: manually define the styles that you want use
@mixin DEPRECATED_flex($flex-grow: 1, $flex-shrink: $flex-grow, $flex-basis: auto) {
  $boxflex: $flex-grow;

  // Box-Flex only supports a flex-grow value so grab the
  // first item in the list and just return that.
  @if type-of($flex-grow) == 'list' {
    $boxflex: nth($flex-grow, 1);
  }

  flex: $flex-grow $flex-shrink $flex-basis;
}

// TODO: remove all use cases
//
// Alternative: manually define the styles that you want use
@mixin DEPRECATED_flexbox($flex-direction, $justify-content: normal, $align-items: normal) {
  align-items: $align-items;
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
}

// TODO: remove all use cases
//
// Alternative: use a class from our Typography, e.g. subheading
@mixin DEPRECATED_font-sizes($mobile-font-size, $desktop-font-size: $mobile-font-size) {
  font-size: DEPRECATED_rem($mobile-font-size);

  @include breakpoint(tablet) {
    font-size: DEPRECATED_rem-desktop($desktop-font-size);
  }
}

// TODO: remove all use cases
//
// Alternative: use a class from our Typography, e.g. subheading
@mixin DEPRECATED_line-heights($mobile-line-height, $desktop-line-height: $mobile-line-height) {
  line-height: DEPRECATED_rem($mobile-line-height);

  @include breakpoint(tablet) {
    line-height: DEPRECATED_rem-desktop($desktop-line-height);
  }
}
