.collection__single-image {
  display: block;
  height: 200px;
  margin: 0 auto;

  @include breakpoint(desktop) {
    height: 300px;
  }
}

.collection__banner-image {
  display: block;
  width: 100%;
  margin-top: $spacing-lg;
}

.collection__able-to-edit {
  font-size: 14px;
}
