.build-your-plan__checkout {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
}

.build-your-plan__footer-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 24px;
  border-top: 1px solid $grey2;
  background-color: $white;
  z-index: map-get($z-index-map, modal) + 1;

  @include breakpoint(tablet) {
    justify-content: flex-end;
    padding-right: 100px;
  }
}

.build-your-plan__footer-info {
  position: relative;

  // Reset native button styles
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: inherit;
}

.build-your-plan__footer-amount {
  display: block;
  margin-right: 5px;

  @include breakpoint(tablet) {
    margin-right: 48px;
  }
}

.build-your-plan__footer-icon {
  vertical-align: middle;
}

.build-your-plan__drawer-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba($black, 0.7);
  z-index: map-get($z-index-map, modal);
}

.build-your-plan__checkout-drawer {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: $white;
  z-index: map-get($z-index-map, modal) + 1;

  @include breakpoint(tablet) {
    right: 100px;
    left: auto;
    width: 375px;
  }
}

.build-your-plan__drawer-loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.build-your-plan__drawer-loading-spinner {
  width: 30px;
  height: 30px;
}

.build-your-plan__checkout-drawer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.build-your-plan__drawer-item {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 16px;
  }
}

.build-your-plan__drawer-item-price {
  text-align: right;

  >* {
    display: block;
  }

  .rtl-direction & {
    text-align: left;
  }
}

.build-your-plan__drawer-item--auto-ship {
  color: $autoship-green;
}

.build-your-plan__drawer-close-icon {
  position: absolute;
  right: 24px;
  bottom: calc(100% + 16px);
  cursor: pointer;
  filter: invert(1); // black icon for white BG, become white icon for dark BG

  @include breakpoint(tablet) {
    right: 0;
  }
}

.build-your-plan__drawer-name {
  flex-grow: 1;
}

.build-your-plan__drawer-price {
  flex-shrink: 0;
  font-weight: bold;
}
