.CountrySelectorGlobal {
  padding-bottom: 16px;
}

.CountrySelectorGlobal__actions {
  > * {
    width: 100%;
    margin-right: 0;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
