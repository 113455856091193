@import '../core';

.forgot-password {
  margin-top: 40px;
}

.forgot-password__input {
  width: 100%;
  margin: $spacing-sm 0;
}

.forgot-password__input {
  height: 44px;
}
