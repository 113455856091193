.ledger {
  .rtl-direction & {
    direction: rtl;
  }
}

.ledger__order-summary-heading {
  margin-top: 0;
}

.ledger__button {
  padding-top: 24px;
  border-top: 1px solid $grey2;
}

.ledger__item {
  display: flex;
  padding: 16px 0;
  border-top: 1px solid $grey2;
}

.ledger__item-label {
  flex-grow: 1;
  padding-right: 16px;

  .rtl-direction & {
    padding-right: 0;
    padding-left: 16px;
  }
}

.ledger__item-value {
  display: flex;
  align-items: center;
}

.ledger__next-month-subtotal {
  display: flex;
  align-items: flex-start;
}

.ledger__next-month-subtotal-icon {
  margin-right: 5px;

  .rtl-direction & {
    margin-left: 5px;
    margin-right: 0;
  }
}

.ledger__next-processing-date {
  display: block;
  margin-top: 3px;
}
