@import '../core';

// Text colors.
.black { color: $black; }
.gray { color: $grey2; }
.gray-dark { color: $grey3; }
.gray-light { color: $grey1; }
.greyish-brown { color: $grey5; }
.green { color: $DEPRECATED_green-flash; }
.nobel { color: $grey3; }
.red { color: $error-red; }
.white { color: $white; }
