.product-information__header {
  display: flex;
  flex-direction: column;
}

.product-information__header__title {
  flex-grow: 1;
}

.product-information__header__title__name {
  margin: 0;
}

.product-information__header__title__nicotine {
  color: $grey3;
}

.product-information__header__price {
  display: block;
  margin-top: $spacing-sm;
}

.product-information__price--small {
  display: block;
}

.product-information__price--discount {
  text-decoration: line-through;
  color: $grey3;
}

.product-information__price--strikethrough > span {
  margin-right: 8px;
}

.product-information__header__price__display {
  margin: 0;
  color: $black;
}

.product-information__header__title__quick-shop-rating {
  margin: $spacing-sm 0;
}

.product-information__header__title__rating,
.product-information__header__price__details {
  margin-top: $spacing-xs;
}

.product-information__header__price__details {
  margin-bottom: 0;
  text-decoration: underline; // required because there is no href attribute
  cursor: pointer; // required because there is no href attribute
}

.product-information__description {
  margin-top: $spacing-md;
  padding-top: $spacing-sm;
  border-top: 1px solid $grey2;

  @include breakpoint(tablet) {
    margin-bottom: $spacing-md;
    padding-bottom: $spacing-md;
    border-bottom: 1px solid $grey2;
  }
}

.product-information__description,
.product-information__description-markdown {
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.product-information__description--has-quick-shop {
  margin-top: $spacing-sm;
  padding-bottom: $spacing-sm;
  border-bottom: 1px solid $grey2;
}
