.product-page--warning {
  width: 100%;
  justify-content: center;

  img {
    max-width: 200px;
  }

  p {
    text-align: center;
  }
}
