.video-content-section {
  @include breakpoint(desktop) {
    align-items: center;
  }
}

.video-content-section__video {
  video {
    width: 100%;
  }
}

.video-content-section__video-align--left {
  flex-direction: row;

  .video-content-section__content {
    margin-top: $spacing-lg;

    @include breakpoint(desktop) {
      margin-top: 0;
    }
  }
}

.video-content-section__video-align--right {
  .video-content-section__content {
    margin-bottom: $spacing-lg;
    order: -1;

    @include breakpoint(desktop) {
      margin-bottom: 0;
    }
  }
}

.video-content-section__video-align--left,
.video-content-section__video-align--right {
  .video-content-section__content {
    text-align: left;
  }

  .video-content-section__video {
    @include breakpoint(desktop) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}
