.subscription-upsell {
  display: flex;
  align-items: flex-start;
  width: 100%;

  .rtl-direction & {
    direction: rtl;
  }
}

.subscription-upsell__content {
  flex-grow: 1;
  padding-right: 16px;
}

.subscription-upsell__heading {
  margin: 0;
}

.subscription-upsell__description {
  margin: 5px 0 0;
}

.subscription-upsell__learn-more-toggle {
  display: inline-block;
  margin-top: 12px;
  padding: 6px 0;
  text-decoration: underline;
  cursor: pointer;
}

.subscription-upsell__toggle-icon {
  width: 20px;
  height: 20px;

  // These are image elements so we do not have the ability to change their fill
  // values
  filter: brightness(0) invert(1);
}
