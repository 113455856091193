$subheader-height: 40px;
$margin-left-and-right: 25px;

.business-modal-subheader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $subheader-height;
  min-height: $subheader-height;
  border-bottom: 1px solid $grey2;
}

.business-modal-subheader__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: $grey2;
  margin-left: $margin-left-and-right;
  margin-right: $margin-left-and-right;
}

.business-modal-subheader__item--active {
  color: $black;
  text-decoration: underline;
}
