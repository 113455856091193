// ---------------------------------------------------------
// ModalHeader Component
// Used with ModalContainer ModalBody ModalFooter Components
// ---------------------------------------------------------

@import '../../../../core';

$spacing-modal-header-y-md: 100px;
$spacing-modal-header-x-md: 710px;

.business-modal-header {
  @include DEPRECATED_flexbox(column, center, center);
  @include DEPRECATED_flex(1);

  border-bottom: 1px solid $grey2;
  min-height: $spacing-modal-header-y-md;
  min-width: 100%;
  padding: $spacing-xs;

  @include breakpoint(tablet) {
    min-width: $spacing-modal-header-x-md;
    padding: $spacing-md;
  }
}

.business-modal-header__row-close {
  @include DEPRECATED_flexbox(row, flex-end, center);
  @include DEPRECATED_flex(1);

  cursor: pointer;
  height: 100%;
  opacity: 0.4;
  width: 100%;

  &:hover {
    opacity: 1;
  }
}

$spacing-modal-header-icon-close-md: 16px;

.business-modal-header__icon-close {
  color: $black;
  height: $spacing-modal-header-icon-close-md;
  width: $spacing-modal-header-icon-close-md;
}

.business-modal-header__row-title {
  @include DEPRECATED_flexbox(row, center, center);
  @include DEPRECATED_flex(1);
  @include DEPRECATED_font-sizes(20px, 24px);

  color: $black;
  font-weight: bold;
  text-transform: uppercase;
  height: 100%;
  width: 100%;
}
