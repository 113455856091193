@import '../../core';
@import './quick-links';

.header__container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: zindex(header) + 1;
}

.header__nav-wrapper {
  position: relative;
  width: 100%;
  z-index: zindex(header);
  border-top: 0.5px solid $grey2;
  box-shadow: 0 2px 3px 0 $box-shadow-color;
  background-color: $white;

  a {
    text-decoration: none;
  }
}

.header--hybrid {
  .header__title {
    display: block;

    @include breakpoint(tablet) {
      display: none;
    }
  }

  .header__logo {
    display: none;

    @include breakpoint(tablet) {
      display: block;
    }
  }
}

.header__quick-links,
.header__navbar-myaccount {
  display: none;
}

.header__title {
  display: none;
}

.header__logo-link {
  cursor: pointer;
  display: flex;
}

.header__navbar {
  position: relative;

  @media only screen and (max-width: $screen-sm-min) {
    &:not(.container-fluid) {
      padding: 0;
    }
  }
}

.header__cart {
  border-left: 0.5px solid $grey2;
}

.header__juul-logo {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header__cart {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.header__nav-wrapper {
  width: 100%;
  background-color: $white;
  border-top: 0.5px solid $grey2;
  z-index: zindex(header) + 1;

  a {
    text-decoration: none;
  }
}

@include breakpoint(tablet) {
  .header__navbar {
    font-size: DEPRECATED_rem-desktop(18px);
  }

  .header__juul-logo {
    justify-content: flex-start;
    left: 5px;
    position: relative;
  }

  .header__quick-links {
    display: block;
    border-bottom: 0.5px solid $grey2;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .header__navbar-myaccount {
    display: flex;
  }

  .header__navbar-myaccount {
    padding: 0;
    border-left: 0.5px solid $grey2;
    cursor: pointer;
  }

  .header__navbar-myaccount-link,
  .header__navbar-myaccount-link--uppercase {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 60px;
    border-bottom: 4px solid transparent;
    padding: 4px 4px 0;
    font-size: 16px;
  }

  .header__navbar-myaccount-link--uppercase {
    font-size: 16px;
    text-transform: uppercase;
  }

  .header__shop-link-down-arrow {
    transform: translate(5px, 0);
  }

  .header__navbar-myaccount:hover,
  .header__navbar-myaccount--active {
    > .header__navbar-myaccount-link--uppercase {
      border-bottom: $header-nav-wayfinder-border-bottom;
    }
  }
}

@include breakpoint(desktop) {
  .header__navbar {
    font-size: DEPRECATED_rem-desktop(18px);
  }
}

.hamburger-icon {
  cursor: pointer;
  height: 16px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 18px;
}

.hamburger-icon__line {
  background: $grey5;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  width: 100%;
}

.hamburger-icon__line:nth-child(1) {
  top: 0;
  transform-origin: left center;
}

.hamburger-icon__line:nth-child(2) {
  top: 6px;
  transform-origin: left center;
}

.hamburger-icon__line:nth-child(3) {
  top: 12px;
  transform-origin: left center;
}

.hamburger-icon--open .hamburger-icon__line:nth-child(1) {
  left: 3px;
  top: 1px;
  transform: rotate(45deg);
}

.hamburger-icon--open .hamburger-icon__line:nth-child(2) {
  opacity: 0;
  width: 0%;
}

.hamburger-icon--open .hamburger-icon__line:nth-child(3) {
  left: 3px;
  top: 14px;
  transform: rotate(-45deg);
}
