@import '../core';

$transition-time: 300ms;
$transition-right-md: $spacing-md / 2;
$transition-right-xl: $spacing-xl / 2;
$transition-right-xxl: $spacing-xxl / 2;
$transition-right-xxxxl: $spacing-xxxxl / 2;

.slide-in-widget {
  display: flex;
  position: fixed;
  z-index: 5;
  bottom: 152px;
  right: $transition-right-md;
  flex-flow: row nowrap;
  align-items: center;
  width: 250px;
  overflow: hidden;
  border-radius: 8px;
  background: $autoship-green;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &.enter {
    right: -300px;
  }

  &.enter-active {
    right: $transition-right-md;
    transition: right $transition-time;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity $transition-time;
  }

  @include breakpoint(tablet) {
    bottom: 32px;
    right: $transition-right-xl;

    &.enter-active {
      right: $transition-right-xl;
      transition: right $transition-time;
    }
  }

  @include breakpoint(desktop) {
    right: $transition-right-xxl;

    &.enter-active {
      right: $transition-right-xxl;
      transition: right $transition-time;
    }
  }

  @include breakpoint(desktop-xlarge) {
    right: $transition-right-xxxxl;

    &.enter-active {
      right: $transition-right-xxxxl;
      transition: right $transition-time;
    }
  }

  @media only screen and (min-width: $layout-max-width + 1) {
    $half-outer-margin: calc((((100vw - #{$layout-max-width}) / 2) + #{$spacing-xxxxl}) / 2);

    right: $half-outer-margin;

    &.enter-active {
      right: $half-outer-margin;
      transition: right $transition-time;
    }
  }
}

.slide-in-widget--error {
  background: $error-red;
}

.slide-in-widget__badge {
  flex: 0 0 44px;
  text-align: center;
}

.slide-in-widget__badge .icon {
  width: 32px;
  filter: invert(1) brightness(100);
}

.slide-in-widget__content {
  flex: 1 1 auto;
  padding: 12px 40px 12px 12px;
  background-color: $white;
  color: $black;
}

.slide-in-widget__content p {
  line-height: 18px;
  margin: 6px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.slide-in-widget__dismiss {
  position: absolute;
  top: 6px;
  right: 6px;
  outline: 0;
  padding: 0;
  background: none;
  border: 0;
  appearance: none;
  font-size: 0;
  cursor: pointer;
}
