.shop-button {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: $black;
  color: $white;
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;

  .rtl-direction & {
    direction: rtl;
  }
}

.shop-button--light {
  background-color: transparent;
  border-color: $grey2;
  color: $black;

  &:hover {
    border-color: $black;
  }
}

.shop-button--auto-ship {
  background-color: $autoship-green;
  border-color: transparent;
  color: $white;
}

.shop-button--wide {
  min-width: 192px;
  justify-content: space-between;
}

.shop-button:disabled {
  background-color: $grey2;
  border-color: $grey2;
  color: $grey4;
  cursor: default;
}

.shop-button__text {
  text-align: left;
  color: inherit;
  padding-right: 16px;

  .rtl-direction & {
    padding-right: 0;
    padding-left: 16px;
    text-align: right;
  }
}

.shop-button__icon {
  min-width: 24px; // ensure that long text does not collapse the icon
}

.shop-button:not(:disabled):not(.shop-button--light) .shop-button__icon {
  filter: brightness(0) invert(1); // turns our dark icon white
}

.shop-button:disabled .shop-button__icon {
  opacity: 0.5;
}
