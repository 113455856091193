@import '../core';

.Form {
  margin-top: 20px;
  text-align: left;
}

.Form__submit-button {
  margin-top: 15px;
  width: 100%;
}

.Field--checkbox {
  padding-left: 20px;
  text-align: left;

  @include DEPRECATED_flexbox(row, normal, flex-start);

  > input {
    flex-shrink: 0;
    margin-right: 10px;
  }

  & + .Field__error {
    margin-top: 5px;
  }
}

html .Field--select {
  background-color: $white;
  margin: 0 0 6px;
  outline: none;

  .Field__error {
    margin-left: 15px;
  }

  // react-select styling.
  .Select {
    &.is-disabled {
      background-color: $grey2;
      border: 1px solid $grey2;
      border-color: $grey2;
      border-radius: 0;

      > .Select-control {
        background-color: $grey2;
      }

      .Select-arrow {
        border-color: $grey2;
      }
    }

    &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
      color: $grey5;
    }
  }
}

html .Field__input {
  /* disable shadow in mobile safari */
  appearance: none;
  border: 1px solid $grey2;
  border-radius: 1px;
  box-shadow: none;
  color: $grey5;
  margin-bottom: 6px;
  outline: none;
  padding: $form-field-vertical-padding $form-field-horizontal-padding;
  width: 100%;

  &:hover {
    border-color: $grey3;
  }

  &:focus {
    border-color: $grey5;
  }

  &::placeholder {
    @include placeholder-text;
  }

  &.Field__input--disabled {
    background-color: $grey2;
    color: $grey5;
  }

  &.Field__input--invalid {
    border-color: $error-red;
    color: $error-red;
  }
}

.Field__error {
  color: $error-red;
  font-size: DEPRECATED_rem(12px);
  margin-bottom: 15px;
  margin-left: 15px;

  @include breakpoint(tablet) {
    font-size: DEPRECATED_rem-desktop(12px);
    line-height: DEPRECATED_rem-desktop(10px);
  }
}

.IconField {
  position: relative;

  & .IconField__icon {
    position: absolute;
    right: 15px;
    top: 15px;

    &:hover {
      cursor: pointer;
    }

    &.IconField__icon--active {
      fill: $DEPRECATED_dark-pastel-green;
    }
  }
}
